import { Box, Container, Toolbar, } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { getStats_api } from "../../api/Statistics/mystatistics";
import { EventAvailable } from '@mui/icons-material';
import DashboardTrainingCard from "./DashboardTrainingCard";
import { getTrainingsFromUser_api } from "../../api/Trainings/training";
import DashboardStatsCard from "./DashboardStatsCard";
import DashboardNextEvents from "./DashboardNextEvents";
import { getNextEvent_api } from "../../api/Events/events";
import { getMyNextReservation_api } from "../../api/Reservations/reservations";
import { getNextMatch_api } from "../../api/Teammatches/Teammatches";
import Footer from "../../components/Footer";
import { getMyTeam } from "../../api/Teams/teams";
import Masonry from "@mui/lab/Masonry/Masonry";
import DashboardTeamsCard from "./DashboardTeamsCard";
import { fetchMe } from "../../api/Users/users";
import { getPosts_api } from "../../api/Posts/post";
import DashboardBlogCard from "./DashboardBlogCard";
import { getSettings_api } from "../../api/Settings/settings";
import { getClubInfo_api } from "../../api/Club/club";
import AdmNuligaInterfaceCard from "../Admin/Dashboard/AdmNuligaInterfaceCard";
import MainContentContainer from "../../components/MainContentContainer";

//import SportsIcon from '@mui/icons-material/Sports';



const DashboardContainer = (props) => {

    const [trainings, setTrainings] = useState(null);
    const [nextMatch, setNextMatch] = useState();
    const [nextEvent, setNextEvent] = useState();
    const [nextBooking, setNextBooking] = useState();
    const [stats, setStats] = useState([{}]);
    const [teams, setTeams] = useState(null);
    const [userData, setUserData] = useState(null);
    const [posts, setPosts] = useState([]);
    const [settings, setSettings] = useState(null);
    const [clubInfo, setClubInfo] = useState();

    const getUserData = () => {
        var promise = fetchMe();
        promise.then((result) => setUserData(result.data))
            .catch();
    }
    const getStats = () => {
        var promise = getStats_api();
        promise.then((result) => { setStats(result.data) })
            .catch();
    }

    const getTrainings = () => {
        var promise = getTrainingsFromUser_api();
        promise.then((result) => { setTrainings(result.data) })
            .catch((error) => { });
    }

    const getNextMatch = () => {
        var promise = getNextMatch_api();
        promise.then((result) => { setNextMatch(result.data) })
            .catch((error) => { });
    }


    const getNextEvent = () => {
        var promise = getNextEvent_api();
        promise.then((result) => { setNextEvent(result.data) })
            .catch();
    }

    const getNextBpooking = () => {
        var promise = getMyNextReservation_api();
        promise.then((result) => { setNextBooking(result.data) })
            .catch((error) => { });
    }

    const getTeam = () => {
        var promise = getMyTeam();
        promise.then((result) => { setTeams(result.data) })
            .catch();
    }

    const getPosts = () => {
        var promise = getPosts_api();
        promise.then((result) => { setPosts(result.data) })
            .catch();
    }

    const getSettings = () => {
        var promise = getSettings_api();
        promise.then((result) => { setSettings(result.data[0]) })
            .catch();
    }
    const getClubInfo = () => {
        var promise = getClubInfo_api();
        promise.then((result) => setClubInfo(result.data))
            .catch();
    }

    useEffect(() => {
        getUserData();
        getTeam();
        getStats();
        getNextMatch();
        getTrainings();
        getNextEvent();
        getNextBpooking();
        getPosts();
        getSettings();
        getClubInfo();
    }, []);

    return (
        <>
            <Header />
            <Toolbar></Toolbar>
            <MainContentContainer py={0}>
                    <Masonry columns={{ xs: 1, md: 2, xl: 2 }} spacing={2} sx={{ m: 0 }}>
                        <DashboardStatsCard data={userData} counts={stats[0]} settings={settings}></DashboardStatsCard>
                        <DashboardNextEvents nextMatch={nextMatch} nextEvent={nextEvent} nextBooking={nextBooking} settings={settings}></DashboardNextEvents>
                        <DashboardTeamsCard teams={teams} counts={stats[0]}></DashboardTeamsCard>
                        <DashboardTrainingCard trainings={trainings} getTrainings={getTrainings} avatar={<EventAvailable sx={{ width: 40, height: 40 }}></EventAvailable>}></DashboardTrainingCard>
                        <DashboardBlogCard post={posts[0]}></DashboardBlogCard>
                        <AdmNuligaInterfaceCard club={clubInfo} title="Mein Verein (NuLiga)" iconTitle></AdmNuligaInterfaceCard>
                    </Masonry>
            </MainContentContainer>
            <Footer adsEnabled/>
        </>
    );

}



export default DashboardContainer;

