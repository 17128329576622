import { Check, Delete, Edit, EmojiEvents, Group, MailSharp, PersonAdd, Warning, Link, Update, InfoOutlined } from '@mui/icons-material';
import { Card, CardContent, Collapse, Grid, Typography, Divider, Chip, Stack, MenuItem, ListItemIcon, Box, CardMedia, ListItem, ListItemText, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { addTeammate, deleteTeammate_api, deleteTeams, getTeammates_api, sendTeammail_api } from '../api/Teams/teams';
import { getUsersNames } from '../api/Users/users';
import AddEditTeamDialog from './AddEditTeamDialog';
import AddUserDialog from './AddUserDialog';
import MailDialog from './MailDialog';
import TeamMemberCard from './TeamMemberCard';
import ActionMenu from './ActionMenu';
import { useNavigate } from 'react-router-dom';
import PrimaryIconButton from './PrimaryIconButton';
import DeleteDialog from './DeleteDialog';
import { useSnackbar } from './SnackbarContext';

function TeamActionMenu(props) {
    return (
        <ActionMenu color="primary">
            <MenuItem onClick={() => { props.editAction(); }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                Bearbeiten
            </MenuItem>
            <MenuItem onClick={() => { props.seasonAction(); }}>
                <ListItemIcon>
                    <Update fontSize="small" />
                </ListItemIcon>
                Saison wechseln
            </MenuItem>
            <MenuItem onClick={() => { props.addMemberAction(); }}>
                <ListItemIcon>
                    <PersonAdd fontSize="small" />
                </ListItemIcon>
                Mitglied hinzufügen
            </MenuItem>
            <MenuItem onClick={() => { props.mailAction(); }}>
                <ListItemIcon>
                    <MailSharp fontSize="small" />
                </ListItemIcon>
                Nachricht an Mannschaft
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={() => { props.showTeammatches(); }}>
                <ListItemIcon>
                    <EmojiEvents fontSize="small" />
                </ListItemIcon>
                Punktspiele anzeigen
            </MenuItem>
            <MenuItem onClick={() => { props.openNuliga(); }}>
                <ListItemIcon>
                    <Link fontSize="small" />
                </ListItemIcon>
                NuLiga-Seite öffnen
            </MenuItem>
            <MenuItem onClick={() => { props.openTeampage(); }}>
                <ListItemIcon>
                    <InfoOutlined fontSize="small" />
                </ListItemIcon>
                Mannschaftsseite öffnen
            </MenuItem>
           
            <Divider></Divider>
            <MenuItem onClick={() => { props.deleteAction(); }}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                Löschen
            </MenuItem>
        </ActionMenu>
    )
}

function TeamCard(props) {
    const { team, getTeams } = props
    const [teammates, setTeammates] = useState([{}]);

    //add user Dialog vars
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [users, setUsers] = useState([{}]);

    const [openEditTeamDialog, setOpenEditTeamDialog] = useState(false);
    const [openMailDialog, setOpenMailDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Mannschaft löschen", warningText: "Es werden alle Mitglieder und Punktspiele unwiderruflich gelöscht", openDeleteDialog, deleteObjectName: team.team, deleteHandler: () => { } });
    const [expanded, setExpanded] = useState(false);

    const { showSnackbar } = useSnackbar();


    const navigate = useNavigate();

    const getTeammatesFromTeam = () => {
        var promise = getTeammates_api(team.id);
        promise.then((result) => { setTeammates(result.data); })
            .catch();
    }
    const deleteTeammate = (userid) => {
        var promise = deleteTeammate_api(team.id, userid);
        promise.then((result) => { getTeammatesFromTeam(); getTeams(); })
            .catch();
    }

    const delTeam = () => {
        var promise = deleteTeams(team.id);
        promise.then((result) => { getTeams(); setExpanded(false); })
            .catch();
    }
    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState, deleteObjectName: team.team, openDeleteDialog: true, deleteHandler: () => delTeam() })
    }

    const handleSubmit = (message, subject) => {
        var promise = sendTeammail_api(message, subject, team.id);
        promise.then((result) => { showSnackbar(result.data, "success"); setOpenMailDialog(false); })
            .catch((error) => { showSnackbar("Fehler beim Senden der Teammails", "error"); });
    }

    //add user dialog submit 
    const addUserDialogSubmit = (targetUserID) => {
        var promise = addTeammate(team.id, targetUserID);
        promise.then((result) => { getTeammatesFromTeam(); setOpenAddUserDialog(false); getTeams(); })
            .catch();
    }

    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
        })
            .catch(error => { });
    }

    useEffect(() => {
        if (openAddUserDialog) {
            getUsersList();
        }
        else {
            //  getTeams();
        }
        return () => {

        };
    }, [openAddUserDialog]);

    useEffect(() => {
        if (expanded) {
            if (team.id) {
                getTeammatesFromTeam();
            }
        }
        return () => {
            setTeammates(null);
        };
    }, [expanded]);

    return (
        <>

            {props.listview ?
                <>
                    <ListItem onClick={()=>navigate(`/teams/${team.id}`)} secondaryAction={<TeamActionMenu openNuliga={()=>{window.open(team.grouplink || team.link, '_blank').focus()}} openTeampage={()=>navigate(`/teams/${team.id}`)} seasonAction={() => { setOpenEditTeamDialog(true); }} showTeammatches={() => { navigate("/teammatches?tab=all&team=" + team.id); }} editAction={() => { setOpenEditTeamDialog(true); }} deleteAction={() => openDeleteDialogAction()} addMemberAction={() => { setOpenAddUserDialog(true); }} mailAction={() => { setOpenMailDialog(true); }} />}>
                        <ListItemIcon>
                            {team.sync_status === 1 ?
                                <Group color={"primary"} />
                                : team.sync_status === 0 ?
                                    <Warning color="warning" />
                                    :
                                    <Group color="primary" />

                            }
                        </ListItemIcon>
                        <ListItemText primary={team.team} secondary={team.tnbchampionship ? decodeURIComponent(team.tnbchampionship) : "keine NuLiga Synchronisierung"}></ListItemText>
                    </ListItem>
                    <Divider></Divider>
                </>
                :
                <Card raised sx={{ borderRadius: 2, borderBottom: 4, borderColor: "primary.main" }} >
                    <Box sx={{ position: "relative" }}>
                        {team.type == "men" ?
                            <CardMedia onClick={()=>navigate(`/teams/${team.id}`)} component="img" image={"https://media.myclubportal.de/teams_men.jpg"} sx={{ height: "125px", objectFit: "cover", objectPosition: "50% 40%", filter: "brightness(200%)contrast(0.7)" }}></CardMedia>
                            :
                            <CardMedia onClick={()=>navigate(`/teams/${team.id}`)} component="img" image={"https://media.myclubportal.de/teams_women.jpg"} sx={{ height: "125px", objectFit: "cover", objectPosition: "50% 50%", filter: "brightness(100%)contrast(1)" }}></CardMedia>
                        }
                        <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 16px)", p: 1 }} onClick={()=>navigate(`/teams/${team.id}`)}>
                            <Stack direction="row" justifyContent={"space-between"}>

                                <Stack alignItems={"center"}>
                                    <Typography variant="h6">{team.team}</Typography>
                                    {team.tnbchampionship && team.tnbid ?
                                        <Typography variant="subtitle1">{team.league || "Nuliga Stats aktualisieren"}</Typography>
                                        :
                                        <Typography variant="subtitle1">{"Keine NuLiga Synchronisierung"}</Typography>
                                    }
                                </Stack>
                                {team.sync_status ?
                                    <>
                                        <Stack alignItems={"center"}>
                                            <Typography align="center" variant={"h6"}>{team.ranking || "na"}</Typography>
                                            <Typography align="center" variant={"subtitle1"}>Ranking</Typography>
                                        </Stack>
                                        <Stack alignItems={"center"}>
                                            <Typography align="center" variant={"h6"}>{team.points || "na"}</Typography>
                                            <Typography align="center" variant={"subtitle1"}>Punkte</Typography>
                                        </Stack>
                                    </>
                                    :
                                    <></>
                                }
                            </Stack>

                        </Box>
                        <Box sx={{ position: "absolute", color: "#fff", top: "0px", left: "0px" }}>
                            {team.tnbchampionship && team.tnbid ?

                                team.sync_status ?
                                    <Tooltip title="NuLiga Synchronisierung erfolgreich - Punktspiele aktualisiert!">
                                        <Chip size="small" label={<Stack direction="row" spacing={1}><Check color="white" /><Typography color="#fff"> {decodeURIComponent(team.tnbchampionship) || ""}</Typography></Stack>} color="success" sx={{ backgroundColor: "success.dark", borderRadius: "0px 0px 8px 0px" }} />
                                    </Tooltip>
                                    :
                                    <Tooltip title="NuLiga Synchronisierung fehlgeschlagen oder nicht durchgeführt!">
                                    <Chip size="small" label={<Stack direction="row" spacing={1}><Warning></Warning><Typography>{decodeURIComponent(team.tnbchampionship)}</Typography></Stack>} color="warning" sx={{ backgroundColor: "warning.light", borderRadius: "0px 0px 8px 0px" }} />
                                    </Tooltip>
                                    :
                                <></>
                            }
                        </Box>
                    </Box>
                    <CardContent sx={{ "&:last-child": { paddingBottom: 1 } }}>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item container xs={12} justifyContent={"space-around"} alignItems={"center"} sx={{ pt: "!unset" }}>
                                <Grid item>
                                    <PrimaryIconButton icon={<InfoOutlined  color="primary" /> } onClickAction={() => {navigate(`/teams/${team.id}`)}}/>
                                </Grid>
                                <Grid item>
                                    {/* <CountWidget count={team.totalMembers} icon={<Group color="success" />} color="text.secondary" onClick={() => setExpanded(!expanded)}></CountWidget> */}
                                    <Stack direction="row" spacing={1} alignItems={"center"} onClick={(e) => {e.preventDefault();e.stopPropagation(); setExpanded(!expanded)}}>
                                        <Group color="primary" />
                                        <Typography variant="h6" sx={{ color: "primary.main" }}>{team.totalMembers}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={1} alignItems={"center"} onClick={(e) => {e.preventDefault();e.stopPropagation();}}>
                                        <EmojiEvents color="primary" />
                                        <Typography variant="h6" sx={{ color: "primary.main" }}>{team.totalTeammatches}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <TeamActionMenu  openNuliga={()=>{window.open(team.grouplink || team.link, '_blank').focus()}} openTeampage={()=>navigate(`/teams/${team.id}`)} seasonAction={() => { setOpenEditTeamDialog(true); }} showTeammatches={() => { navigate("/teammatches?tab=all&team=" + team.id); }} editAction={() => { setOpenEditTeamDialog(true); }} deleteAction={() => openDeleteDialogAction()} addMemberAction={() => { setOpenAddUserDialog(true); }} mailAction={() => { setOpenMailDialog(true); }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Divider></Divider>
                        <CardContent sx={{ bgcolor: "#eeeeee" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TeamMemberCard team={team} teammates={teammates} deleteHandler={deleteTeammate} addMemberHandler={(e)=>{e.stopPropagation();setOpenAddUserDialog(true)}}></TeamMemberCard>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </Card >
            }
            <AddEditTeamDialog open={openEditTeamDialog} onClose={() => { setOpenEditTeamDialog(false); }} getAllTeams={getTeams} team={team} isEdit={true} teamNames={[]}></AddEditTeamDialog>
            <MailDialog open={openMailDialog} onClose={() => { setOpenMailDialog(false) }} title="Teamnachricht" submitName="Abschicken" handleSubmit={handleSubmit} isHidden={true} />
            <AddUserDialog users={users} openAddUserDialog={openAddUserDialog} addUserDialogSubmit={addUserDialogSubmit} onClose={() => { setOpenAddUserDialog(false) }} title={"Spieler einladen"}></AddUserDialog>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
        </>
    )
}

export default TeamCard