import { Alert, Card, CardContent, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react'

function TeamStatsCard(props) {
    const { team, raised } = props;
    return (
        <Card raised={raised} sx={{ borderRadius: 2, border: 0, width: 'inherit' }}>
            <CardContent>
                <Grid container item direction="row" alignItems="center" spacing={2}>
                    <Grid container item direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs={6} align="left">
                            <Typography variant="h6">Team-Statistik</Typography>
                        </Grid>
                        {team &&
                            <Grid item align="right">
                                <Link rel="noreferrer" target="_blank" href={team.grouplink || team.link}><Typography variant="body2">NuLiga Profil</Typography></Link>
                            </Grid>
                        }

                        <Grid item xs={12} >
                            <Divider></Divider>
                        </Grid>
                    </Grid>
                    {team && team.ranking && team.points && team.matchpoints ?
                        <>
                            {/* <CardHeader title={team.team || team.opponent} titleTypographyProps={{ variant: "h6" }} subheader={team.league || team.location} /> */}

                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent={"space-between"}>
                                        <Stack>
                                            <Typography variant="h6">{team.team || team.opponent}</Typography>
                                            <Typography variant="subtitle1" >{team.league || team.location}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography variant="h6" color="text.secondary">{team && team.tnbchampionship && decodeURIComponent(team.tnbchampionship)}</Typography>
                                            {/* <Typography variant="subtitle1" textAlign='right'>Saison</Typography> */}
                                        </Stack>

                                    </Stack>

                                </Grid>
                                <Grid item xs={12}>
                                    <Divider></Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent={"space-around"}>

                                        <Stack justifyContent={"center"} alignItems={"center"}>
                                            <Typography align="right" variant={"h4"}>{team.ranking}</Typography>
                                            <Typography align="left" variant={"body1"} color="secondary" fontWeight={'bold'}>Ranking</Typography>
                                        </Stack>
                                        <Stack justifyContent={"center"} alignItems={"center"}>
                                            <Typography align="right" variant={"h4"}>{team.points}</Typography>
                                            <Typography align="left" variant={"body1"} color="secondary" fontWeight={'bold'}>Punkte</Typography>
                                        </Stack>
                                        <Stack justifyContent={"center"} alignItems={"center"}>
                                            <Typography align="right" variant={"h4"}>{team.matchpoints}</Typography>
                                            <Typography align="left" variant={"body1"} color="secondary" fontWeight={'bold'}>Matchpunkte</Typography>
                                        </Stack>

                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>
                                {/* <Grid container item spacing={2}>
                                    <Grid item xs={6} >
                                        <Typography align="right" variant={"h4"}>{team.ranking}</Typography>
                                    </Grid>
                                    <Grid container item xs={6} alignItems="center">
                                        <Typography align="left" variant={"body1"}>Ranking</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography align="right" variant={"h4"}>{team.points}</Typography>
                                </Grid>
                                <Grid container item xs={6} alignItems="center">
                                    <Typography align="left" variant={"body1"}>Punkte</Typography>
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item xs={6} >
                                        <Typography align="right" variant={"h4"}>{team.matchpoints}</Typography>
                                    </Grid>
                                    <Grid container item xs={6} alignItems="center">
                                        <Typography align="left" variant={"body1"}>Matchpunkte</Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Alert severity="info"><Typography>Keine Nuliga Informationen vorhanden</Typography></Alert>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TeamStatsCard