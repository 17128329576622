import axios from "axios";
import getSessionData from "../../utils/getSession";
import { format } from "date-fns";

export const getMyTeammatches = async (query) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/myteammatches?" + query,
    { headers: sessionData }
  );
};

export const getTeammatches = async (teamid, next = "") => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches?teamid=" + teamid + "&next=" + next,
    { headers: sessionData }
  );
};

export const getTeammatches_api = async (query) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches?" + query,
    { headers: sessionData }
  );
};

export const setTeammatch = async (teamid, opponent, date, location, season, sync) => {
  const sessionData = getSessionData();

  var data = {
    teamid: teamid,
    opponent: opponent,
    date: date,
    location: location,
    season: season,
    sync: sync
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/teammatches", data,
    { headers: sessionData }
  );
};



export const getTeammatchParticpantsStatus = async (teammatchid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/myteammatchparticipant/" + teammatchid,
    { headers: sessionData }
  );
};

export const getTeammatchGuestsStatus = async (teammatchid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatchguest/" + teammatchid,
    { headers: sessionData }
  );
};

export const deleteTeammatch_api = async (teammatchid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/teammatches/" + teammatchid,
    { headers: sessionData }
  );
};

export const deleteGuest_api = async (teammatchid, guestid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/teammatches/" + teammatchid + "/guests/" + guestid,
    { headers: sessionData }
  );
};

export const deleteTeammachtStatus_api = async (teammatchid, userid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/teammatches/" + teammatchid + "/participants/" + userid,
    { headers: sessionData }
  );
};


export const updateParticipantStatus = async (id, status, userid) => {
  const sessionData = getSessionData();

  const Data = {
    teammatchid: id,
    status: status,
    userid: userid
  };

  //create new registration
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/myteammatchparticipant",
    Data, { headers: sessionData }
  );
};

export const setInitialParticipantStatus = async (id, status, userid) => {
  const sessionData = getSessionData();

  const Data = {
    teammatchid: id,
    status: status,
    userid: userid
  };

  //create new registration
  return await axios.post(
    process.env.REACT_APP_API_URL + "/myteammatchparticipant", Data, { headers: sessionData }
  );
};

export const createGuest = async (teammatchid, userid) => {
  const sessionData = getSessionData();

  const Data = {
    teammatchid: teammatchid,
    userid: userid
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/teammatchguest", Data, { headers: sessionData }
  );
};

export const getMyGuestMatches = async (query) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + `/myguestmatches`, { headers: sessionData }
  );
};

export const updateMyTeammatch = async (id, opponent, location, date, sync) => {
  const sessionData = getSessionData();
  //format date to before store in db
  var formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss")
  
  const Data = {
    opponent: opponent,
    location: location,
    date: formattedDate,
    sync: sync
  };

  //create new registration
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/myteammatches/" + id,
    Data, { headers: sessionData }
  );
};

export const getNextMatch_api = async () => {
  const sessionData = getSessionData();

  //create new registration
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches/next", { headers: sessionData }
  );
};

export const updateTeammatches_api = async () => {
  const sessionData = getSessionData();

  var data = {};
  //create new registration
  return await axios.post(
    process.env.REACT_APP_API_URL + "/teammatches/update", data, { headers: sessionData }
  );
};

export const getLiveMatches_api = async () => {
  const sessionData = getSessionData();

  //create new registration
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches/live", { headers: sessionData }
  );
};

export const updateLiveScore_api = async (id, teamScore, opponentScore) => {
  const sessionData = getSessionData();

  var data = {
    teamScore: teamScore,
    opponentScore: opponentScore
  };
  //create new registration
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/teammatches/" + id + "/livescore", data, { headers: sessionData }
  );
};

export const getTeammachtesHomeGrouped_api = async (length) => {
  const sessionData = getSessionData();
  //create new registration
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches/homegrouped?length="+length, { headers: sessionData }
  );
};

export const getTeammatchReservationStatus_api = async () => {
  const sessionData = getSessionData();
  //create new registration
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches/reservation-status", { headers: sessionData }
  );
};

export const getTeammatchActionRequired_api = async () => {
  const sessionData = getSessionData();
  //create new registration
  return await axios.get(
    process.env.REACT_APP_API_URL + "/teammatches/actionrequired", { headers: sessionData }
  );
};

