import { CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, ImageList, ImageListItem, List, ListItemButton, ListItemText, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TertiaryButton from '../../../components/TertiaryButton'
import PrimaryButton from '../../../components/PrimaryButton'
import { Close, Collections, Language, UploadFile } from '@mui/icons-material';
import { getFiles_api, uploadFile_api } from '../../../api/Upload/upload';
import { useSnackbar } from '../../../components/SnackbarContext';
import { createAd_api, updateAd_api } from '../../../api/Advertisements/advertisements';
import { useCreateAdvertisement, useUpdateAdvertisement } from '../../../hooks/useAdvertisement';
import { isValid } from 'date-fns';


function AdvertisementDialog({ open, banner, onClose, isEdit, getAds, ...props }) {

    const [adlink, setAdlink] = useState(banner?.link);
    const [adname, setAdname] = useState(banner?.name);
    const [pictureMode, setPictureMode] = useState();
    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState(banner?.image_url);
    const [images, setImages] = useState();
    const [submitDiasbled, setSubmitDisabled] = useState();
    const { showSnackbar } = useSnackbar();
    const { mutate: createAd, isCreating, createError } = useCreateAdvertisement();
    const { mutate: updateAd, isUpdating, updateError } = useUpdateAdvertisement();



    const submitHandler = () => {

        if (isEdit) {
            updateAd({ "imageUrl": image, "link": adlink, "name": adname, id: banner.id }, { onSuccess: () => { onClose(); } })
        }
        else {
            createAd({ "imageUrl": image, "link": adlink, "name": adname }, { onSuccess: () => { onClose(); } });
        }
    }


    const handleFileUpload = (event) => {

        // if no file is given 
        if (!event.target.files) {
            return;
        }
        else {
            if (event.target.files[0].type.includes("image/")) {
                setUploading(true);
                // send file as formData
                const data = new FormData()
                data.append('file', event.target.files[0])
                var promise = uploadFile_api(data);
                promise.then((result) => { setImage(result.data[0].img_url); showSnackbar("Bild erfolgreich hochgeladen", "success"); setUploading(false); })
                    .catch((error) => { showSnackbar("Fehler " + error, "error"); setUploading(false); });
            }
            showSnackbar("Kein gültiges Bildformat", "warning");
        }
    }

    function isValidURL(url) {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    const readFiles = () => {
        var promise = getFiles_api();
        promise.then((result) => { setImages([...result.data]); })
            .catch((error) => { showSnackbar("Bilder konnten nicht geladen werden!", "error"); });
    }

    useEffect(() => {
        if (open) {
            readFiles();
            if (!isEdit) {
                setAdname("");
                setAdlink("");
                setImage("");
            }
        }

    }, [open]);

    useEffect(() => {
        if (adname == "" || !isValidURL(adlink) || !isValidURL(image)) {
            setSubmitDisabled(true)
        }
        else {
            setSubmitDisabled(false);
        }
    }, [adname, adlink, image]);

    return (
        <Dialog maxWidth="md" open={open} onClose={() => onClose} fullWidth>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>{isEdit ? "Werbebanner bearbeiten" : "Werbebanner erstellen"}</DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems={"center"} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        <Divider><Typography>Name</Typography></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required label="Name" value={adname} fullWidth onChange={(e) => setAdname(e.target.value)} error={adname ==""}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider><Typography>Link</Typography></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required label="Link" value={adlink} fullWidth onChange={(e) => setAdlink(e.target.value)} error={!isValidURL(adlink)}></TextField>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField label="Bild" value={url} fullWidth onChange={(e) => setURL(e.target.value)}></TextField>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Divider><Typography>Bild</Typography></Divider>
                    </Grid>

                    <Grid item container xs={12} justifyContent={"center"}>
                        {image ?
                            <CardMedia component={"img"} image={image} sx={{ height: "150px", objectFit: 'contain', backgroundColor: "#eee" }} />
                            :
                            // <Alert severity='info' sx={{ width: "100%" }}>
                            //     <Typography>kein Bild ausgewählt</Typography>
                            // </Alert>
                            <></>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            <ListItemButton onClick={() => { setPictureMode("existing") }}>
                                <ListItemText primary={"Vorhandenes Bild"} secondary={"Wähle eine Bild aus den bisherigen Bildern aus"} primaryTypographyProps={{ fontWeight: 500 }} />
                                <Collections color="primary" />
                            </ListItemButton >
                            <label htmlFor="id-upload-input">
                                <ListItemButton onClick={() => { setPictureMode(null) }}>
                                    <ListItemText primary={"Neues Bild"} secondary={"Lade ein neues Bild von deinen lokalen Medien hoch"} primaryTypographyProps={{ fontWeight: 500 }} />
                                    <UploadFile color="primary" />
                                    <input type="file" id="id-upload-input" hidden accept="image/*" onChange={(event) => handleFileUpload(event)} />
                                </ListItemButton>
                            </label>
                            <ListItemButton onClick={() => { pictureMode === "url" ? setPictureMode(null) : setPictureMode("url") }}>
                                <ListItemText primary={"Bild URL"} secondary={"Verlinke ein Bild aus dem Inernet"} primaryTypographyProps={{ fontWeight: 500 }} />
                                <Language color="primary" />
                            </ListItemButton>
                        </List>
                    </Grid>


                    {pictureMode === "existing" &&
                        <Grid item xs={12}>
                            <Stack direction={"row"} justifyContent={"center"}>
                                <TertiaryButton startIcon={<Close></Close>} text="schließen" onClick={() => setPictureMode(null)}></TertiaryButton>
                            </Stack>
                            <ImageList sx={{ height: "250px" }}>
                                {images && images.map((image, i) => (

                                    <ImageListItem key={i} onClick={() => { setImage(image.url); setPictureMode(null); }}>
                                        <img srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`} src={`${image.url}?w=248&fit=crop&auto=format`} loading="lazy" alt="" />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                    }

                    {pictureMode === "url" ?
                        <Grid item xs={12}>
                            <TextField fullWidth required value={image} onChange={(event) => setImage(event.target.value)} name="image" label="Bild URL"></TextField>
                        </Grid>
                        :
                        <></>
                    }
                </Grid>
            </DialogContent>
            <Divider></Divider>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <TertiaryButton text="Abbrechen" onClick={onClose}></TertiaryButton>
                <PrimaryButton text="Bestätigen" disabled={isCreating || isUpdating || submitDiasbled} onClick={() => { submitHandler(); }}></PrimaryButton>
            </DialogActions>
        </Dialog >
    )
}

export default AdvertisementDialog