import { Box, Button, CardMedia, Container, Grid, Stack, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { Dashboard, EmojiEvents, Event, ExpandMore, FitnessCenter, Group, Link, Login, Person, PersonAdd, PollSharp, SportsTennis } from "@mui/icons-material";
import FeatureCard from "../../components/FeatureCard";
import { useNavigate } from "react-router-dom";
import { BOOKING, DASHBOARD, LOGIN, MYEVENTS, MYSURVEYS, MYTEAM, MYTEAMMATCHES, MYTRAININGGROUPS, SIGNUP, USERSME } from "../../navigation/CONSTANTS";
import FeatureAnnouncementCard from "../../components/FeatureAnnouncementCard";
import Masonry from "@mui/lab/Masonry/Masonry";
import TertiaryButton from "../../components/TertiaryButton";
import features from "./Announcements.json"

const HomeContainer = () => {

    // const [features, setFeatures] = useState(null);
    const [featureLimit, setFeatureLimit] = useState(3);
    const navigate = useNavigate();

    const images = ["https://images.pexels.com/photos/171568/pexels-photo-171568.jpeg",
        "https://images.pexels.com/photos/2961964/pexels-photo-2961964.jpeg",
        "https://images.pexels.com/photos/793176/pexels-photo-793176.jpeg"];

    const externalLink = (link) => {
        window.open(link, '_blank');
    }

    const increaseFeatureLimit = () => {
        setFeatureLimit(featureLimit + 3);
    }

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <>
            <Header />
            <Toolbar variant="dense" sx={{ height: "40px" }}></Toolbar>
            <Box sx={{ position: "relative" }}>
                <CardMedia image={images[0]} sx={{ height: { xs: "300px", md: "400px", xl: "400px" }, transition: "2s", filter: "brightness(80%) blur(5px)" }}></CardMedia>
                <Box sx={{ position: "absolute", color: "#fff", bottom: "10%", left: "10%", transform: "translate(0, 0)" }}>
                    <Typography sx={{ typography: { xs: "h5", md: "h3" } }}>{process.env.REACT_APP_CLUB_NAME}</Typography>
                    <Typography sx={{ typography: { xs: "body1", md: "h6" }, mt: { xs: "10px", md: "20px" }, fontWeight: { xs: 500, md: 500 } }}>{"MyClubPortal"}</Typography>
                    <Typography sx={{ typography: { xs: "body2", md: "body1" } }}>Das webbasierte Portal für Tennisvereine</Typography>
                    <Typography sx={{ typography: { xs: "body2", md: "body1" } }}>Tennisplatzbuchung & smartes Vereinsmanagement</Typography>


                    <Stack direction="row" spacing={3} sx={{ mt: "10px" }}  >
                        <Button variant="contained" color="white" sx={{ color: "text.primary", textTransform: "none" }} startIcon={<Login></Login>} onClick={() => { navigate(LOGIN) }}>{"Login"}</Button>
                        <Button variant="outlined" color="white" sx={{ textTransform: "none", textDecoration: "underline" }} startIcon={<PersonAdd />} onClick={() => { navigate(SIGNUP) }}>{"Registrieren"}</Button>
                        <Box sx={{ display: { xs: "none", sm: "inline" } }}>
                            <Button variant="text" color="white" sx={{ textTransform: "none", textDecoration: "underline", width: "fit-content" }} startIcon={<Link></Link>} onClick={() => { externalLink(process.env.REACT_APP_CLUB_URL) }}>{process.env.REACT_APP_CLUB_ABBREVATION + " Homepage"}</Button>
                        </Box>
                    </Stack>
                    {/* <Button variant="text" color="white" sx={{ textTransform: "none", textDecoration: "underline", width: "fit-content"}} startIcon={<Link></Link>} onClick={() => { externalLink(process.env.REACT_APP_CLUB_URL) }}>{process.env.REACT_APP_CLUB_ABBREVATION + " Homepage"}</Button> */}
                </Box>
            </Box>
            <Container maxWidth={"xl"} sx={{ width: "auto", mt: 5 }}>

                <Grid container direction="row" justifyContent={"center"} spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="text.secondary">Schnellzugriff</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<Dashboard fontSize="small" color="primary" />} name="Dashboard" route={DASHBOARD} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<Person fontSize="small" color="primary" />} name="Profil" route={USERSME} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<SportsTennis fontSize="small" color="primary" />} name="Buchen" route={BOOKING} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<Group fontSize="small" color="primary" />} name="Mannschaft" route={MYTEAM} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<EmojiEvents fontSize="small" color="primary" />} name="Punktspiele" route={MYTEAMMATCHES} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<Event fontSize="small" color="primary" />} name="Events" route={MYEVENTS} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<FitnessCenter fontSize="small" color="primary" />} name="Training" route={MYTRAININGGROUPS} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                        <FeatureCard icon={<PollSharp fontSize="small" color="primary" />} name="Umfragen" route={MYSURVEYS} />
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 3 }} spacing={4} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="text.secondary">Neue Funktionen</Typography>
                    </Grid>
                    {/* {features && features.map((feature, i) => (
                        <Grid item xs={12} md={6} lg={4} key={i}>
                            <FeatureAnnouncementCard title={feature.title} description={feature.description} imageUrl={feature.image_url} imageProps={{ filter: "grayscale(0.6)", maxHeight: "180px" }} />
                        </Grid>
                    ))
                    } */}
                    <Grid item xs={12}>
                        <Masonry columns={{ xs: 1, sm: 2, md: 3, xl: 3 }} spacing={4} sx={{ width: "auto", m: 0 }}>
                            {features && features.map((feature, i) => (
                                i < featureLimit &&
                                <FeatureAnnouncementCard title={feature.title} description={feature.description} release={feature.release} imageUrl={feature.image_url} imageProps={{ filter: "grayscale(0.6)" }} />
                            ))
                            }
                        </Masonry>
                        {features && features.length > featureLimit &&
                            <Grid item xs={12}>
                                <Stack direction={"row"} justifyContent={"center"}>
                                    <TertiaryButton text="Mehr anzeigen" endIcon={<ExpandMore />} onClick={() => increaseFeatureLimit()}></TertiaryButton>
                                </Stack>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Container >
            <Footer adsEnabled/>
        </>
    );
};

export default HomeContainer;
