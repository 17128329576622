import React from "react";
import ReactDOM from "react-dom/client"; // Updated for React 18
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, yellow } from "@mui/material/colors";
import { QueryClient, QueryClientProvider } from "react-query";

// Theme configuration
const myTheme = createTheme({
  palette: {
    warning: {
      main: "rgba(255,94,5,1)",
      trans: "rgba(255,94,5,0.2)"
    },
    action: {
      main: "rgba(93,17,206,1)",
      trans: "rgba(93,17,206,0.2)"
    },
    success: {
      main: "rgb(34,186,83)",
      trans: "rgba(86, 202, 0, 0.12)"
    },
    white: {
      main: "rgba(255,255,255,1)",
      trans: "rgba(255,255,255,0.2)"
    },
    black: {
      main: "rgba(0,0,0,1)",
      trans: "rgba(0,0,0,0.2)"
    },
    grey: {
      main: grey[500]
    },
    yellow: {
      main: yellow[500],
      light: yellow[300],
      dark: yellow[700]
    }
  }
});

// Redux configuration
const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2
};

const initialState = {
  isAuthenticated: false,
  firstname: undefined,
  lastname: undefined,
  email: undefined,
  id: undefined,
  isAdmin: false,
  isCaptain: false,
  teamID: undefined,
  teamname: undefined,
  has_notifications: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "SIGNIN":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

const pReducer = persistReducer(persistConfig, reducer);
const store = createStore(pReducer);
const persistor = persistStore(store);

// Create a QueryClient instance
const queryClient = new QueryClient();

// React 18 root rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
<QueryClientProvider client={queryClient}>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={myTheme}>
          <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>
  </QueryClientProvider>

  // </React.StrictMode>
);

// Performance reporting
reportWebVitals();
