import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer'
import { useParams, useSearchParams } from 'react-router-dom'
import { getTeams_api, getTeamsSeries_api } from '../../../api/Teams/teams';
import { Alert, AlertTitle, Breadcrumbs, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import MainContentContainer from '../../../components/MainContentContainer';
import TitleCard from '../../../components/TitleCard';
import { Groups } from '@mui/icons-material';
import TeamStatsCard from '../../../components/TeamStatsCard';
import TeamMemberCard from '../../../components/TeamMemberCard';
import SeriesCard from '../../../components/SeriesCard';
import { ADM_TEAMS } from '../../../navigation/CONSTANTS';
import Masonry from '@mui/lab/Masonry';

function TeamContainer() {

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [team, setTeam] = useState();
    const [series, setSeries] = useState();

    const getTeamSeries = () => {
        var promise = getTeamsSeries_api(id);
        promise.then((result) => { setSeries(result.data) })
            .catch();
    }

    const getTeam = () => {
        var promise = getTeams_api(searchParams.toString());
        promise.then((result) => { setTeam(result.data[0]) })
            .catch(() => { });
    }

    useEffect(() => {
        searchParams.set("id", id)
        getTeam();
        getTeamSeries();
    }, []);
    return (
        <>
            <Header />
            <TitleCard id="crd-title-teampage" title={"Mannschaften"} subheader={"Verwalte die Mannschaften vom HTC"} icon={<Groups sx={{ width: 40, height: 40 }}></Groups>}></TitleCard>
            <MainContentContainer pb={4} wrapInCard={true}>
                {/* <TeamCardLight team={team}></TeamCardLight> */}
                <Grid container direction={"row"} spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs>
                            <Link underline="hover" color='inherit' href={ADM_TEAMS}>
                                Mannschaften
                            </Link>
                            <Typography>{team?.team}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Masonry columns={{ xs: 1, sm: 2, xl: 2 }} spacing={2} sx={{ m: 0 }}>
                    <TeamStatsCard team={team} raised></TeamStatsCard>
                    <TeamMemberCard team={team} teammates={team?.members} deleteHandler={() => { }}></TeamMemberCard>
                    <Card sx={{ borderRadius: 2 }} raised={true}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} align="left">
                                    <Typography variant="h6">Reservierungen Punktspiele</Typography>
                                </Grid>
                                <Grid item xs={12}><Divider></Divider></Grid>
                                {series?.length > 0 ? series?.map((serie, i) => (
                                    <Grid item xs={12} key={i}>
                                        <SeriesCard serie={serie} getSeries={getTeamSeries} />
                                    </Grid>
                                ))
                                    :
                                    <Grid item xs={12} >
                                        <Alert severity="info">
                                            <AlertTitle>Information</AlertTitle>
                                            <Typography color="text.secondary">Keine Reservierungen vorhanden</Typography>
                                        </Alert>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Masonry>
            </MainContentContainer>
            <Footer />
        </>
    )
}

export default TeamContainer