import { Alert, AlertTitle, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import { AccountBalance, LocationOn, Navigation } from '@mui/icons-material';

function DirectionsDialog({ address, open, onClose,clubName, ...props }) {
    const sanitizedAddress = address?.replace('(Clubhaus - bewirtschaftet)', '').trim();
    const mapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(sanitizedAddress)}&output=embed`;
    const externalMapsUrl = `https://www.google.de/maps/place/${encodeURIComponent(sanitizedAddress)}`;

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
            <DialogTitle sx={{ backgroundColor: '#eee' }}>Anfahrt</DialogTitle>
            <Divider />
            <DialogContent>
                <Card elevation={0} variant="outlined" sx={{ mt: 1 }}>
                    <CardContent>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AccountBalance color="primary" />
                                <Typography fontWeight={"bold"} color="text.secondary" variant="h6">{clubName}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <LocationOn color="primary" />
                                <Typography color="text.secondary">{sanitizedAddress}</Typography>
                            </Stack>
                            <Divider />
                            <Alert severity="info">
                                <AlertTitle>
                                    Wichtig!
                                </AlertTitle>
                                Überprüfen Sie die Adressinformationen ggf. auf Plausibilität.</Alert>
                            <iframe
                                title="Google Maps"
                                width="100%"
                                height="300"
                                style={{ border: 0, borderRadius: '8px' }}
                                loading="lazy"
                                allowFullScreen
                                src={mapsUrl}
                            ></iframe>
                        </Stack>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <SecondaryButton text="Abbrechen" onClick={() => onClose()} />
                <PrimaryButton startIcon={<Navigation />} text="Google Maps" onClick={() => window.open(externalMapsUrl, '_blank')} />
            </DialogActions>
        </Dialog>
    );
}

export default DirectionsDialog;
