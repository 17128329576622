import { CheckCircleOutline, Clear, HelpOutline, HighlightOffOutlined, Login, Logout } from '@mui/icons-material'
import { ButtonGroup, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import React from 'react'
import PrimaryIconButton from './PrimaryIconButton'
import { useSelector } from 'react-redux'

function TeammatchParticipantsList({teammatch,maxHeight,filter=2,...props}) {

    const userId = useSelector((state) => state.id)

    return (
        <List sx={{ maxHeight: maxHeight, overflowY: "auto" }}>
            {teammatch?.participants && teammatch?.participants.filter((participant) =>(participant.status==filter || (filter!=null && filter!=0 && filter!=1))).map((participant, i) => (
                <ListItem key={i}>
                    {participant.status === null ? <ListItemIcon><HelpOutline sx={{ color: 'warning.main' }} fontSize='large'/></ListItemIcon> : <></>}
                    {participant.status === 1 ? <ListItemIcon><CheckCircleOutline sx={{ color: 'success.main' }} fontSize='large'/></ListItemIcon> : <></>}
                    {participant.status === 0 ? <ListItemIcon><HighlightOffOutlined sx={{ color: 'secondary.main' }} fontSize='large'/></ListItemIcon> : <></>}
                    <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + (participant.nuliga_lk ?? "")} primaryTypographyProps={{ variant: "body1", fontWeight: 'bold', color: "text.primary" }} />
                    {teammatch?.isCaptain === 1 || props.isAdmin === true || participant.user_id == userId ?
                        <ButtonGroup>
                            <PrimaryIconButton icon={<Login color="success" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch?.id, 1, participant.user_id, participant.status) }} />
                            <PrimaryIconButton icon={<Logout color="error" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch?.id, 0, participant.user_id, participant.status) }} />
                            <PrimaryIconButton icon={<Clear />} disabled={props.disabled} onClickAction={() => { props.deleteStatusTeammatch(teammatch?.id, participant.user_id) }} />
                        </ButtonGroup> : <></>}
                </ListItem>
            ))}
            {/* {teammatch?.participants.length > 5 && <ListSubheader inset={true}><PrimaryIconButton icon={expParticipants ? <ExpandLess /> : <ExpandMore />} onClickAction={() => { setExpParticipants(!expParticipants); }}></PrimaryIconButton></ListSubheader>} */}
            {teammatch?.guests && teammatch?.guests.length > 0 && <ListSubheader><Divider>{"Aushilfen"}</Divider></ListSubheader>}
            {teammatch?.guests && teammatch?.guests.map((participant, i) => (
                <ListItem key={i}>
                    {participant.status === null ? <ListItemIcon><HelpOutline sx={{ color: 'warning.main' }} /></ListItemIcon> : <></>}
                    {participant.status === 1 ? <ListItemIcon><CheckCircleOutline sx={{ color: 'success.main' }} /></ListItemIcon> : <></>}
                    {participant.status === 0 ? <ListItemIcon><HighlightOffOutlined sx={{ color: 'secondary.main' }} /></ListItemIcon> : <></>}
                    <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + (participant.nuliga_lk ?? "")} primaryTypographyProps={{ variant: "body1", fontWeight: 'bold', color: "text.primary" }} />
                    {teammatch?.isCaptain === 1 || props.isAdmin === true || participant.id == userId ?
                        <ButtonGroup>
                            <PrimaryIconButton icon={<Login color="success" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch?.id, 1, participant.id, participant.status) }} />
                            <PrimaryIconButton icon={<Logout color="error" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch?.id, 0, participant.id, participant.status) }} />
                            <PrimaryIconButton icon={<Clear></Clear>} disabled={props.disabled} onClickAction={(e) => { props.deleteGuest(teammatch?.id, participant.id) }} />
                        </ButtonGroup>
                        : <></>}
                </ListItem>
            ))}
        </List>
    )
}

export default TeammatchParticipantsList