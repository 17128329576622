import { Alert, Card, CardContent, Grid, Stack, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react'
import { Directions } from '@mui/icons-material';

function TeammatchDirectionsCard(props) {
    const { teammatch, raised } = props;
    const [sanitizedOutdoorAddress, setSanitizedOutdoorAddress] = useState();
    const [sanitizedIndoorAddress, setSanitizedIndoorAddress] = useState();
    const [mapsUrlIndoor, setMapsUrlIndoor] = useState(null);
    const [mapsUrlOutdoor, setMapsUrlOutdoor] = useState(null);
    const [mapsUrl, setMapsUrl] = useState();

    useEffect(() => {
        setSanitizedOutdoorAddress(teammatch?.outdoor_address?.replace('(Clubhaus - bewirtschaftet)', '').trim());
        setSanitizedIndoorAddress(teammatch?.indoor_address?.replace('(Clubhaus - bewirtschaftet)', '').trim());
    }, [teammatch]);

    useEffect(() => {
        if (sanitizedIndoorAddress) {
            setMapsUrlIndoor(`https://www.google.com/maps?q=${encodeURIComponent(sanitizedIndoorAddress)}&output=embed`);
            setMapsUrl(`https://www.google.com/maps?q=${encodeURIComponent(sanitizedIndoorAddress)}&output=embed`);
        }

    }, [sanitizedIndoorAddress]);

    useEffect(() => {
        if (sanitizedOutdoorAddress) {
            setMapsUrlOutdoor(`https://www.google.com/maps?q=${encodeURIComponent(sanitizedOutdoorAddress)}&output=embed`)
        }

    }, [sanitizedOutdoorAddress]);

    return (
        <Card raised={raised} sx={{ borderRadius: 2, border: 0, width: 'inherit' }}>
            <CardContent>
                <Grid container item direction="row" alignItems="center" spacing={2}>
                    {teammatch && (teammatch?.indoor_address && teammatch?.outdoor_address) ?
                        <>
                            <Grid container item spacing={2}>

                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} onClick={() => setMapsUrl(mapsUrlIndoor)} sx={{ cursor: "pointer" }}>
                                            <Stack>
                                                <Typography color="secondary" fontWeight={"bold"} textTransform={"uppercase"}>Halle</Typography>
                                                <Typography color="text.secondary" fontWeight={mapsUrl == mapsUrlIndoor ? "bold" : "default"}>{teammatch?.indoor_address}</Typography>
                                            </Stack>
                                            <Directions color={mapsUrl == mapsUrlIndoor ? "secondary" : "disabled"} onClick={() => setMapsUrl(mapsUrlIndoor)}></Directions>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} onClick={() => setMapsUrl(mapsUrlOutdoor)} sx={{ cursor: "pointer" }}>
                                            <Stack>
                                                <Typography color="secondary" fontWeight={"bold"} textTransform={"uppercase"}>Außenplatz</Typography>
                                                <Typography color="text.secondary" fontWeight={mapsUrl == mapsUrlOutdoor ? "bold" : "default"}>{teammatch?.outdoor_address}</Typography>
                                            </Stack>
                                            <Directions color={mapsUrl == mapsUrlOutdoor ? "secondary" : "disabled"} ></Directions>
                                        </Stack>
                                        {mapsUrl &&
                                            <iframe
                                                title="Google Maps"
                                                width="100%"
                                                height="300"
                                                style={{ border: 0, borderRadius: '8px' }}
                                                loading="lazy"
                                                allowFullScreen
                                                src={mapsUrl}
                                            ></iframe>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                             <Typography color="secondary" fontWeight={"bold"} textTransform={"uppercase"}>Adresse</Typography>
                            <Alert severity="info"><Typography>Keine Addressinformationen vorhanden</Typography></Alert>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TeammatchDirectionsCard