import { Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { setTeammatch, updateMyTeammatch } from '../api/Teammatches/Teammatches';
import DateTimeField from './DateTimeField'
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import TeamSelect from './TeamSelect';
import { getMyTeam, getTeams_api } from '../api/Teams/teams';
import { useSnackbar } from './SnackbarContext';

function AddEditTeammatchDialog(props) {

    const { open, onClose, teammatch, getTeammatches, adminMode, isEdit } = props;
    const [opponent, setOpponent] = useState("");
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState("");
    const [isNuLiga, setIsNuLiga] = useState(false);
    const [teamid, setTeamid] = useState(null);
    const [season, setSeason] = useState("");
    const [disableName, setDisableName] = useState(false);
    const [team, setTeam] = useState(null);
    const {showSnackbar} = useSnackbar();


    const handleChange = (event) => {
        switch (event.target.name) {
            case "opponent":
                setOpponent(event.target.value);
                break;
            case "location":
                setLocation(event.target.value);
                break;
            case "isNuligaSync":
                setIsNuLiga(event.target.checked);
                break;
            case "season":
                setSeason(event.target.value);
                break;
            default:
                break;
        }
    }

    const submitHandlerEdit = () => {
        var promise = null;
        if (isEdit) {
            //todo distinguish between admin and user (parameter: team & season)
            promise = updateMyTeammatch(teammatch.id, opponent, location, date, isNuLiga);
            promise.then((result) => { getTeammatches(); onClose();showSnackbar("Punktspiel erfolgreich bearbeitet"); })
                .catch((error) => { showSnackbar("Fehler beim Bearbeiten des Punkstpiels");});
        }
        else {
            promise = setTeammatch(teamid, opponent, date, location, encodeURIComponent(season), isNuLiga);
            promise.then((result) => { getTeammatches(); onClose();showSnackbar("Punktspiel erfolgreich erstellt"); })
                .catch((error) => { showSnackbar("Fehler beim Erstellen des Punkstpiels");});
        }

    }

    const getTeam = () => {
        var promise = null;
        if (!adminMode) {
            promise = getMyTeam(teamid);
        }
        else {
            promise = getTeams_api("id=" + teamid);
        }
        promise.then((result) => { if (result.data.length > 0) { setTeam(result.data[0]); setSeason(decodeURIComponent(result.data[0].tnbchampionship)); console.log(decodeURIComponent(result.data[0].tnbchampionship)) } })
    }

    useEffect(() => {
        if (teammatch) {
            setTeamid(teammatch.teamid);
            setOpponent(teammatch.opponent);
            setDate(new Date(teammatch.date));
            setLocation(teammatch.location);
            setIsNuLiga(Boolean(teammatch.sync));
            setSeason(decodeURIComponent(teammatch.season));
        }
        else {
            setTeamid(null);
        }
    }, [open]);

    useEffect(() => {
        if (isNuLiga && isEdit && open) {
            setDisableName(true)
        }
        else {
            setDisableName(false);
        }
        return () => {

        };
    }, [isNuLiga]);

    useEffect(() => {
        if (teamid && !isEdit) {
            getTeam();
        }
    }, [teamid]);

    useEffect(() => {
        if (team) {
            setSeason(decodeURIComponent(team.tnbchampionship));
        }

    }, [team]);
    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>{isEdit ? "Punktspiel bearbeiten" : "Spiel erstellen"}</DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Grid container direction="row" spacing={2}>

                        <Grid item xs={12}>
                            <TeamSelect teamid={teamid} setTeamid={setTeamid} showAllOption={false} size="medium" myTeams={!adminMode ? true : false} disabled={isEdit}></TeamSelect>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField disabled={disableName} fullWidth label="Gegner" name="opponent" id="opponent" value={opponent} onChange={handleChange}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimeField label="Datum" date={new Date(date)} setDate={setDate} size={"medium"}></DateTimeField>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <Stack direction="row" spacing={1} justifyContent={"center"}>
                                    <Chip label="Heim" color={location=="(H)" ? "primary" : "disabled"} onClick={() => setLocation("(H)")} size="medium" />
                                    <Chip label="Auswärts" color={location=="(A)" ? "primary" : "disabled"} onClick={() => setLocation("(A)")} size="medium" />
                                </Stack>
                                {/* <TextField fullWidth name="location" value={location} onChange={handleChange} label="Ort" /> */}
                            </Stack>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth disabled={true} name="season" value={season} onChange={handleChange} label="Saison" InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid container item alignItems={"center"} justifyContent={"space-between"} xs={12}>
                            <Box>
                                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>NuLiga Synchronisierung</Typography>
                                <Typography variant="body2">Soll das Spiel automatisch aktualisiert werden?</Typography>
                            </Box>
                            <Switch size="medium" name="isNuligaSync" id="isNuligaSync" checked={isNuLiga} onChange={handleChange}></Switch>
                        </Grid>

                    </Grid>
                </DialogContent>
                <Divider></Divider>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton onClick={onClose} text={"Abbrechen"} />
                    <PrimaryButton text="Bestätigen" onClick={submitHandlerEdit} disabled={!opponent || opponent=="" ||!teamid}/>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddEditTeammatchDialog