import { Delete, Edit, Login, Logout, PersonAdd, HelpOutline, CheckCircleOutline, HighlightOffOutlined, Group, Clear, ExpandMore, ExpandLess, LockOutlined, Link, Directions, SportsTennis, BallotSharp, Person, Expand, LinkSharp } from '@mui/icons-material';
import { Box, ButtonGroup, Card, Chip, CardContent, Collapse, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Stack, Typography, MenuItem, ListItemIcon, CardMedia, CardHeader, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Menu } from '@mui/material'
import { compareAsc, format, isFuture } from 'date-fns';
import { de } from 'date-fns/locale';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { updateParticipantStatus, setInitialParticipantStatus, createGuest, deleteGuest_api, deleteTeammachtStatus_api, deleteTeammatch_api } from '../api/Teammatches/Teammatches';
import { getUsersNames } from '../api/Users/users';
import AddUserDialog from './AddUserDialog';
import AddEditTeammatchDialog from './AddEditTeammatchDialog';
import CountWidget from './CountWidget';
import StatusChip from './StatusChip';
import PrimaryIconButton from './PrimaryIconButton';
import DynamicExpandIcon from './DynamicExpandIcon';
import ActionMenu from './ActionMenu';
import DirectionsDialog from './DirectionsDialog';
import DeleteDialog from './DeleteDialog';
import AddEditSurveyDialog from './AddEditSurveyDialog';
import Survey from './Survey';
import TeammatchTimelineItem from './TeammatchTimelineItem';
import { useTheme } from '@emotion/react';
import ActionMenuItem from './ActionMenuItem';
import { useSnackbar } from './SnackbarContext';


function TeammatchActionMenu(props) {

    const isAdmin = useSelector((state)=>state.isAdmin);

    return (
        <ActionMenu color={props.color || "primary"} isDrawer={props.isDrawer} fontSize={props.fontSize} drawerTitle={props.drawerTitle} drawerSubtitle={props.drawerSubtitle} tooltipTitle={"Menü"} iconSize={"small"}>
            {props.teammatch.ranking && props.teammatch.points && props.teammatch.matchpoints && props.isDrawer ?
                <>
                    <Grid container item spacing={2} justifyContent={"space-around"} sx={{ py: 1 }}>
                        <Grid item  >
                            <Stack alignItems={"center"}>
                                <Typography align="center" variant={"h5"}>{props.teammatch.ranking}</Typography>
                                <Typography align="center" variant={"body1"}>Ranking</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack alignItems={"center"}>
                                <Typography align="center" variant={"h5"}>{props.teammatch.points}</Typography>
                                <Typography align="center" variant={"body1"}>Punkte</Typography>
                            </Stack>
                        </Grid>
                        <Grid item  >
                            <Stack alignItems={"center"}>
                                <Typography align="center" variant={"h5"}>{props.teammatch.matchpoints}</Typography>
                                <Typography align="center" variant={"body1"}>Matchpunkte</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider sx={{mb:1}}></Divider>
                </>
                :
                <div></div>
            }
            {
                !props.adminMode && compareAsc(new Date(props.teammatch.date), new Date()) > 0 ?
                    props.teammatch.isParticipant === null ?
                        <div>
                            <ActionMenuItem itemText={"Anmelden"} icon={<Login fontSize={props.fontSize} color="success" />} onClick={() => { props.signInAction(); }} />
                            <ActionMenuItem itemText={"Abmelden"} icon={<Logout fontSize={props.fontSize} color="error" />} onClick={() => { props.signOutAction(); }} />
                        </div>
                        :
                        props.teammatch.isParticipant === 0 ?
                            <ActionMenuItem itemText={"Anmelden"} icon={<Login fontSize={props.fontSize} color="success" />} onClick={() => { props.signInAction(); }} />
                            :

                            props.teammatch.isParticipant === 1 ?
                                <ActionMenuItem itemText={"Abmelden"} icon={<Logout fontSize={props.fontSize} color="error" />} onClick={() => { props.signOutAction(); }} />
                                :
                                <div></div>
                    :
                    <div></div>
            }
            <ActionMenuItem itemText={"NuLiga"} icon={<LinkSharp fontSize={props.fontSize} />} onClick={() => { props.nuligaLink(); }} />
            <ActionMenuItem itemText={"Anfahrt (Draußen)"} icon={<Directions fontSize={props.fontSize} />} onClick={() => { props.mapsActionIndoor(); }} />
            <ActionMenuItem itemText={"Anfahrt (Halle)"} icon={<Directions fontSize={props.fontSize} />} onClick={() => { props.mapsActionOutdoor(); }} />
            <ActionMenuItem itemText={"Aushilfe einladen"} icon={<PersonAdd fontSize={props.fontSize} />} onClick={() => { props.addMemberAction(); }} />
            <ActionMenuItem itemText={"Verknüpfte Umfrage erstellen"} icon={<BallotSharp fontSize={props.fontSize} />} onClick={() => { props.createLinkedSurvey(); }} />

            {
                (props.isCaptain === true || isAdmin === true) && !props.isGuest &&
                <div>
                    <ActionMenuItem itemText={"Bearbeiten"} icon={<Edit fontSize={props.fontSize} />} onClick={() => { props.editAction(); }} />
                    < Divider></Divider>
                    <ActionMenuItem itemText={"Löschen"} icon={<Delete fontSize={props.fontSize} />} onClick={() => { props.deleteAction(); }} />
                </div>
            }
        </ActionMenu >
    )
}


function TeammatchCard(props) {
    const { teammatch, getTeammatches, isGuest, getGuestMatches, adminMode, isList, isTimeLine, hideConnector, showTeam } = props;
    const statusColor = ['warning.main', 'success.main'];
    const [expanded, setExpanded] = useState(false);
    const [expParticipants, setExpParticipants] = useState(false);
    const [expParticiAccordion, setExpParticiAccordion] = useState(false);
    const [expSurveyAccordion, setExpSurveyAccordion] = useState(false);


    const [disabled, setDisabled] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Punktspiel löschen", warningText: "Es werden alle Zusagen unwiderruflich gelöscht", openDeleteDialog, deleteObjectName: teammatch.opponent, deleteHandler: () => { } });
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const isAdmin = useSelector((state)=>state.isAdmin);
    const userID = useSelector((state)=>state.id);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { showSnackbar } = useSnackbar();


    //add user Dialog vars
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [users, setUsers] = useState([{}]);
    const [targetUserID, setTargetUserID] = useState(null);

    //edit teammatch dialog
    const [openAddEditDialog, setopenAddEditDialog] = useState(false);
    const [directionsDialogState, setDirectionsDialogState] = useState({ open: false, address: "" })

    // survey dialog
    const [openSurveyDialog, setOpenSurveyDialog] = useState(false);
    const [surveyIsEdit, setSurveyIsEdit] = useState(false);

    const navigate = () => {
        window.open(teammatch.link, "_blank")
    }

    const setStatusTeammatch = (id, target_status, userid, current_status) => {
        setDisabled(true);
        var promise = null;
        if (current_status != null) {
            promise = updateParticipantStatus(id, target_status, userid);
        }
        else {
            promise = setInitialParticipantStatus(id, target_status, userid);
        }
        promise.then(result => {
            if (isGuest) {
                getGuestMatches();
            }
            else {
                getTeammatches();
            }
            setDisabled(false);
        })
            .catch((error) => { setDisabled(false); });
    }
    const deleteStatusTeammatch = (teammatchid, userid) => {
        var promise = deleteTeammachtStatus_api(teammatchid, userid);
        promise.then((result) => { getTeammatches(); })
            .catch(error => { });
    }

    const deleteGuest = (temamatchid, userid) => {
        var promise = deleteGuest_api(temamatchid, userid);
        promise.then((result) => { getTeammatches(); })
            .catch((error) => { })
    }

    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState,deleteObjectName: teammatch.opponent, openDeleteDialog: true, deleteHandler: () => deleteTeammatch() })
    }

    const deleteTeammatch = () => {
        var promise = deleteTeammatch_api(teammatch.id);
        promise.then((result) => { getTeammatches(); setExpanded(false) })
            .catch();
    }

    const expandHandler = () => {
        setExpanded(!expanded);
        setExpParticiAccordion(true);
        setExpSurveyAccordion(false)
    }

    const expandSurveyHandler = () => {
        setExpanded(!expanded);
        setExpParticiAccordion(false);
        setExpSurveyAccordion(true)
    }

    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
        })
            .catch(error => { });
    }

    const addUserDialogSubmit = (targetUserID) => {
        var promise = createGuest(teammatch.id, targetUserID);
        promise.then((result) => { getTeammatches(); showSnackbar("User eingeladen", "success"); setOpenAddUserDialog(false); })
            .catch((error) => { showSnackbar(error.message, "error"); });
    }

    const createLinkedSurvey = () => {
        setOpenSurveyDialog(true);
    }

    const openEditDialog = (survey) => {
        setSelectedSurvey(survey);
        setSurveyIsEdit(true);
        setOpenSurveyDialog(true);
    }


    return (
        <>
            {isList ?
                <>
                    <ListItem onClick={()=>{adminMode ? navigate(`/teammatches/${teammatch.id}`): navigate(`/myteammatches/${teammatch.id}`)}} secondaryAction={<TeammatchActionMenu adminMode={adminMode} teammatch={teammatch} isGuest={isGuest} isAdmin={isAdmin} isCaptain={props.isCaptain} mapsActionIndoor={() => setDirectionsDialogState({ open: true, address: teammatch.indoor_address })} mapsActionOutdoor={() => setDirectionsDialogState({ open: true, address: teammatch.outdoor_address })} signOutAction={() => { setStatusTeammatch(teammatch.id, 0, userID, teammatch.isParticipant) }} signInAction={() => { setStatusTeammatch(teammatch.id, 1, userID, teammatch.isParticipant) }} addMemberAction={() => { setOpenAddUserDialog(true); getUsersList(); }} editAction={() => setopenAddEditDialog(true)} deleteAction={() => openDeleteDialogAction()} />}>
                        <ListItemIcon><SportsTennis color="primary" /></ListItemIcon>
                        <ListItemText disableTypography primary={<Typography variant="body2" fontWeight={500} >{teammatch.teamName}</Typography>} secondary={<Stack><Typography variant='body2'>{teammatch.opponent}</Typography><Typography variant="body2">{format(new Date(teammatch.date), "dd.MM.yy. HH:mm", { locale: de }) + " | " + teammatch.location + " | " + teammatch.totalParticipants}</Typography></Stack>}></ListItemText>
                    </ListItem>
                    <Divider></Divider>
                </>

                : isTimeLine ?
                    <TeammatchTimelineItem teammatch={teammatch} getTeammatches={getTeammatches} isGuest={isGuest} adminMode={adminMode} hideConnector={hideConnector} setStatusTeammatch={setStatusTeammatch} deleteStatusTeammatch={deleteStatusTeammatch} isAdmin={isAdmin} deleteGuest={deleteGuest} openEditDialog={openEditDialog} showTeam={isGuest || showTeam ? true : false}
                        TeammatchMenu={<TeammatchActionMenu fontSize={isSmallScreen ? "medium" : "small"} drawerTitle={teammatch.opponent} drawerSubtitle={"Punktspielmenü"} color={isFuture(new Date(teammatch.date)) ? "grey" : "grey"} isDrawer={true} adminMode={adminMode} teammatch={teammatch} isGuest={isGuest} isAdmin={isAdmin} isCaptain={props.isCaptain} mapsActionIndoor={() => setDirectionsDialogState({ open: true, address: teammatch.indoor_address })} mapsActionOutdoor={() => setDirectionsDialogState({ open: true, address: teammatch.outdoor_address })} signOutAction={() => { setStatusTeammatch(teammatch.id, 0, userID, teammatch.isParticipant) }} signInAction={() => { setStatusTeammatch(teammatch.id, 1, userID, teammatch.isParticipant) }} addMemberAction={() => { setOpenAddUserDialog(true); getUsersList(); }} editAction={() => setopenAddEditDialog(true)} deleteAction={() => openDeleteDialogAction()} createLinkedSurvey={() => createLinkedSurvey()} nuligaLink={() => { navigate() }} />}
                    />

                    :
                    <Card raised sx={{ borderRadius: 1, borderLeft: 4, borderColor: "primary.main" }}>
                        <Box sx={{ position: "relative", width: "100%" }}>
                            <CardMedia image={"https://media.myclubportal.de/tennis-court-render-3d-illustrationV2.jpg"} sx={{ height: "150px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>
                            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 0.5 }}>
                                <Grid container direction="row" item xs={12} alignItems={"center"} sx={{ flexWrap: "nowrap", overflowX: "clip" }}>
                                    <Grid item>
                                        {!teammatch.obsolete ?
                                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, borderRadius: 2, alignItems: "center" }}>
                                                <Typography sx={{ fontWeight: 'bold', typography: { xs: "h6", md: "h6" } }}>{teammatch.date && format(new Date(teammatch.date), "dd.MM", { locale: de })}</Typography>
                                                <Typography sx={{ fontWeight: 'bold', typography: { xs: "body1", md: "body1" } }}>{teammatch.date && format(new Date(teammatch.date), "HH 'Uhr'", { locale: de })}</Typography>
                                            </Box>
                                            :
                                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, borderRadius: 2, alignItems: "center" }}>
                                                <Typography sx={{ fontWeight: "bold", typography: { xs: "body1", md: "h6" } }}>{teammatch.date && format(new Date(teammatch.date), "dd.MM", { locale: de })}</Typography>
                                                <Chip size={props.fontSize} color="error" label="retired" sx={{ width: "max-content" }}></Chip>
                                            </Box>
                                        }
                                    </Grid>
                                    <Grid item zeroMinWidth xs>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, alignItems: "left", fontWeight: 400 }}>
                                            <Typography noWrap variant="h6" sx={{ fontWeight: 'bold', typography: { xs: "h6", md: "h6" } }}>{teammatch.opponent}</Typography>
                                            {/* {(adminMode || isGuest)
                                        ?
                                        <Typography noWrap sx={{ fontWeight: 'bold', typography: { xs: "body2", md: "body1" } }}>{teammatch.teamName}</Typography> */}
                                            <Typography variant="body1">{teammatch.location == "(H)" ? "Heim" : "Auswärts"}</Typography>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ position: "absolute", bottom: "0%", left: "50%", transform: "translate(-50%,50%)" }}>

                                {!adminMode ? <StatusChip status={teammatch.isParticipant}></StatusChip> : <></>}
                            </Box>
                            <Box sx={{ position: "absolute", color: "#fff", top: "5px", left: "10px", width: "calc(100% - 8px)" }}>
                                <Stack direction="row" justifyContent={"center"}>
                                    <Chip size={props.fontSize} color="info" sx={{ borderRadius: 1 }} label={<Stack direction="row" spacing={1}><Typography variant="body2">{teammatch.teamName}</Typography></Stack>} />
                                </Stack>
                            </Box>
                        </Box>
                        <CardContent sx={{
                            p: 1, "&:last-child": { paddingBottom: 1 }
                        }}>
                            <Grid container direction="row" alignItems="center" spacing={0}>
                                <Grid item container justifyContent={"space-around"} alignItems={"center"} sx={{ pt: 1 }}>
                                    <Grid item>
                                        <PrimaryIconButton icon={<Link color="primary" />} onClickAction={() => navigate()}></PrimaryIconButton>
                                    </Grid>

                                    <Grid item>
                                        <CountWidget icon={<Group color="primary" />} count={teammatch.totalParticipants} color="primary" onClick={() => expandHandler()}></CountWidget>
                                    </Grid>
                                    {teammatch.assignmentsData && teammatch.assignmentsData.length > 0 &&
                                        <Grid item>
                                            <PrimaryIconButton icon={<BallotSharp color="primary" />} onClick={() => { expandSurveyHandler() }} tooltipTitle={"Verknüpfte Umfragen"} />
                                        </Grid>
                                    }

                                    {!adminMode && compareAsc(new Date(teammatch.date), new Date()) > 0 ?
                                        teammatch.isParticipant === null ?
                                            <Grid item>
                                                <Stack direction="row" spacing={1}>
                                                    <PrimaryIconButton icon={<Login color="success" />} onClickAction={() => { setStatusTeammatch(teammatch.id, 1, userID, teammatch.isParticipant) }} />
                                                    <PrimaryIconButton icon={<Logout color="error" />} onClickAction={() => { setStatusTeammatch(teammatch.id, 0, userID, teammatch.isParticipant) }} />
                                                </Stack>
                                            </Grid>

                                            : teammatch.isParticipant === 0 ?

                                                <Grid item>
                                                    <PrimaryIconButton icon={<Login color="success" />} onClickAction={() => { setStatusTeammatch(teammatch.id, 1, userID, teammatch.isParticipant) }} />
                                                </Grid>

                                                : teammatch.isParticipant === 1 &&

                                                <Grid item>
                                                    <PrimaryIconButton icon={<Logout color="error" />} onClickAction={() => { setStatusTeammatch(teammatch.id, 0, userID, teammatch.isParticipant) }} />
                                                </Grid>
                                        :
                                        <Grid item>
                                            <LockOutlined color="primary"></LockOutlined>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <TeammatchActionMenu adminMode={adminMode} teammatch={teammatch} isGuest={isGuest} isAdmin={isAdmin} isCaptain={props.isCaptain} mapsActionIndoor={() => setDirectionsDialogState({ open: true, address: teammatch.indoor_address })} mapsActionOutdoor={() => setDirectionsDialogState({ open: true, address: teammatch.outdoor_address })} signOutAction={() => { setStatusTeammatch(teammatch.id, 0, userID, teammatch.isParticipant) }} signInAction={() => { setStatusTeammatch(teammatch.id, 1, userID, teammatch.isParticipant) }} addMemberAction={() => { setOpenAddUserDialog(true); getUsersList(); }} editAction={() => setopenAddEditDialog(true)} deleteAction={() => openDeleteDialogAction()} createLinkedSurvey={() => createLinkedSurvey()} nuligaLink={() => { navigate() }} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}><Divider> <DynamicExpandIcon onClick={() => expandHandler()} expanded={expanded} sx={{ p: 0 }} /></Divider></Grid>

                                <Grid container item spacing={2} justifyContent={"space-around"}>
                                    <Grid item  >
                                        <Stack alignItems={"center"}>
                                            <Typography align="center" variant={"h5"}>{teammatch.ranking}</Typography>
                                            <Typography align="center" variant={"body1"}>Ranking</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack alignItems={"center"}>
                                            <Typography align="center" variant={"h5"}>{teammatch.points}</Typography>
                                            <Typography align="center" variant={"body1"}>Punkte</Typography>
                                        </Stack>

                                    </Grid>
                                    <Grid item  >
                                        <Stack alignItems={"center"}>
                                            <Typography align="center" variant={"h5"}>{teammatch.matchpoints}</Typography>
                                            <Typography align="center" variant={"body1"}>Matchpunkte</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent sx={{ bgcolor: "#eeeeee" }}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid container item xs={12} justifyContent="center">
                                        <Accordion sx={{ width: 'inherit', borderLeft: 2, borderColor: "secondary.main" }} expanded={expParticiAccordion} disableGutters>
                                            <AccordionSummary expandIcon={<DynamicExpandIcon />} onClick={() => setExpParticiAccordion(!expParticiAccordion)}><Typography variant="h6" >Teilnehmer</Typography></AccordionSummary>
                                            <AccordionDetails sx={{}}>
                                                <Divider></Divider>
                                                <List dense>
                                                    {teammatch.participants && teammatch.participants.length > 0 && teammatch.participants.map((participant, i) => (
                                                        <ListItem key={i}>
                                                            {participant.status === null ? <ListItemAvatar><HelpOutline sx={{ color: 'warning.main' }} /></ListItemAvatar> : <></>}
                                                            {participant.status === 1 ? <ListItemAvatar><CheckCircleOutline sx={{ color: 'success.main' }} /></ListItemAvatar> : <></>}
                                                            {participant.status === 0 ? <ListItemAvatar><HighlightOffOutlined sx={{ color: 'secondary.main' }} /></ListItemAvatar> : <></>}
                                                            <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + participant.nuliga_lk} primaryTypographyProps={{ variant: "body1", fontWeight: 'bold', color: "text.primary" }} />
                                                            {teammatch.isCaptain === 1 || isAdmin === true ?
                                                                <ButtonGroup>
                                                                    <PrimaryIconButton icon={<Login color="success" />} disabled={props.disabled} onClickAction={() => { setStatusTeammatch(teammatch.id, 1, participant.user_id, participant.status) }} />
                                                                    <PrimaryIconButton icon={<Logout color="error" />} disabled={props.disabled} onClickAction={() => { setStatusTeammatch(teammatch.id, 0, participant.user_id, participant.status) }} />
                                                                    <PrimaryIconButton icon={<Clear />} disabled={props.disabled} onClickAction={() => { deleteStatusTeammatch(teammatch.id, participant.user_id) }} />
                                                                </ButtonGroup> : <></>}
                                                        </ListItem>
                                                    ))}
                                                    {teammatch.participants && teammatch.participants.length > 5 && <ListSubheader inset={true}><PrimaryIconButton icon={expParticipants ? <ExpandLess /> : <ExpandMore />} onClickAction={() => { setExpParticipants(!expParticipants); }}></PrimaryIconButton></ListSubheader>}
                                                    {teammatch.guests && teammatch.guests.length > 0 && <ListSubheader><Divider>{"Aushilfen"}</Divider></ListSubheader>}
                                                    {teammatch.guests && teammatch.guests.map((participant, i) => (
                                                        <ListItem key={i}>
                                                            {participant.status === null ? <ListItemAvatar><HelpOutline sx={{ color: 'warning.main' }} /></ListItemAvatar> : <></>}
                                                            {participant.status === 1 ? <ListItemAvatar><CheckCircleOutline sx={{ color: 'success.main' }} /></ListItemAvatar> : <></>}
                                                            {participant.status === 0 ? <ListItemAvatar><HighlightOffOutlined sx={{ color: 'secondary.main' }} /></ListItemAvatar> : <></>}
                                                            <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + participant.nuliga_lk} primaryTypographyProps={{ variant: "body1", fontWeight: 'bold', color: "text.primary" }} />
                                                            {teammatch.isCaptain === 1 || isAdmin === true ?
                                                                <ButtonGroup>
                                                                    <PrimaryIconButton icon={<Login color="success" />} disabled={props.disabled} onClickAction={() => { setStatusTeammatch(teammatch.id, 1, participant.id, participant.status) }} />
                                                                    <PrimaryIconButton icon={<Logout color="error" />} disabled={props.disabled} onClickAction={() => { setStatusTeammatch(teammatch.id, 0, participant.id, participant.status) }} />
                                                                    <PrimaryIconButton icon={<Clear></Clear>} disabled={props.disabled} onClickAction={(e) => { deleteGuest(teammatch.id, participant.id) }} />
                                                                </ButtonGroup>
                                                                : <></>}
                                                        </ListItem>
                                                    ))}
                                                </List>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ width: 'inherit', borderLeft: 2, borderColor: "secondary.main" }} expanded={expSurveyAccordion} disableGutters>
                                            <AccordionSummary expandIcon={<ExpandMore />} onClick={() => setExpSurveyAccordion(!expSurveyAccordion)}><Typography variant="h6">Verknüpfte Umfragen</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <Stack spacing={2}>
                                                    {teammatch.assignmentsData && teammatch.assignmentsData.map((survey, i) => (
                                                        <Survey key={i} variant="outlined" surveyData={survey} getSurveys={() => { getTeammatches() }} openEditDialog={openEditDialog} />
                                                    ))}
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </Card >
            }
            <AddUserDialog users={users} setTargetUserID={setTargetUserID} openAddUserDialog={openAddUserDialog} addUserDialogSubmit={addUserDialogSubmit} onClose={() => { setOpenAddUserDialog(false) }} title={"Spieler einladen"}></AddUserDialog>
            <AddEditTeammatchDialog isEdit={true} teammatch={teammatch} open={openAddEditDialog} setOpen={setopenAddEditDialog} onClose={() => { setopenAddEditDialog(false) }} getTeammatches={getTeammatches} adminMode={adminMode}></AddEditTeammatchDialog>
            <DirectionsDialog clubName={teammatch?.opponent} open={directionsDialogState.open} onClose={(directionsDialogState) => setDirectionsDialogState({ directionsDialogState: false, ...directionsDialogState })} address={directionsDialogState.address}></DirectionsDialog>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
            <AddEditSurveyDialog open={openSurveyDialog} onClose={() => { setOpenSurveyDialog(false); setSurveyIsEdit(false); }} getSurveys={() => { getTeammatches() }} isEdit={surveyIsEdit} selectedSurvey={selectedSurvey} targetTeamsArgs={[{ team: teammatch.teamName, id: teammatch.teamid }]} assignedTeammatchArgs={[teammatch]} adminMode={adminMode} />
        </>
    )
}


export default TeammatchCard