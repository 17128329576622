import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getMyTeam, getTeams, getTeams_api } from '../api/Teams/teams';

function TeamSelect(props) {
    const { teamid, setTeamid, showAllOption, size, multipleSelect, addTeam,disabled, myTeams = null } = props;
    const [team, setTeam] = useState({});
    const [teams, setTeams] = useState([{}]);
    const [state, setState] = useState(null);

    const getAllTeams = () => {
        var promise = getTeams_api();
        promise.then((result) => { setTeams(result.data) })
            .catch();
    }

    const getMyTeams = () => {
        var promise = getMyTeam();
        promise.then((result) => { setTeams(result.data); })
            .catch();
    }

    const handleChange = (event) => {
        if (!multipleSelect) {
            setTeamid(event.target.value);
        }
    };

    useEffect(() => {
        if (myTeams) {
            getMyTeams();
        }
        else {
            getAllTeams();
        }
    }, []);

    return (
        <FormControl fullWidth size={size}>
            <InputLabel id="team-select-label">Mannschaft</InputLabel>
            <Select fullWidth size={size} label="Mannschaft" labelId='team-select-label' id="team" value={teamid} onChange={handleChange} disabled={disabled}>
                {showAllOption &&
                    <MenuItem value={''}>Alle</MenuItem>
                }
                {teams && teams.map((team, i) => (
                    <MenuItem value={team.id} key={i} onClick={() => { if (multipleSelect) { addTeam(team); } }}>{team.team}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default TeamSelect