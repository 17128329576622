import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import TitleCard from '../../../components/TitleCard'
import MainContentContainer from '../../../components/MainContentContainer'
import Footer from '../../../components/Footer'
import { Alert, AlertTitle, Divider, Grid, Typography } from '@mui/material'
import AdvertisementCard from './AdvertisementCard'
import { getAds_api } from '../../../api/Advertisements/advertisements'
import { AdsClick } from '@mui/icons-material'
import PrimaryButton from '../../../components/PrimaryButton'
import AdvertisementDialog from './AdvertisementDialog'
import { useAdvertisements } from '../../../hooks/useAdvertisement'
import { useQueryClient } from 'react-query'

function AdvertisementContainer() {
    const [open,setOpen] = useState();

    const queryClient = useQueryClient();
    const { data: advertisements, isLoading, error } = useAdvertisements();


    return (
        <>
            <Header></Header>
            <TitleCard id="crd-title-blog" title={"Werbebanner"} subheader={"Erstellen und verwalte deine Werbebanner"} icon={<AdsClick sx={{ width: 40, height: 40 }} />}></TitleCard>

            <MainContentContainer>
                <Grid container spacing={1} sx={{mb: 1}} justifyContent={"flex-end"}>
                    <Grid item>
                        <PrimaryButton text="Erstellen" onClick={()=>{setOpen(true)}}></PrimaryButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                </Grid>
                <Grid container item justifyContent={"row"} alignContent={"center"} spacing={2} >
                    {advertisements?.length > 0 ? advertisements?.map((ad, i) => (
                        <Grid item xs={12} sm={4} key={i}>
                            <AdvertisementCard banner={ad}  />
                        </Grid>
                    ))
                    :
                    <Grid item xs={12}>
                        <Alert severity='info'>
                            <AlertTitle>Information</AlertTitle>
                            <Typography>Aktuell sind keine Werbebanner vorhanden. Werbebanner werden unten in der Fußzeile angezeigt.</Typography>
                        </Alert>
                        </Grid>
                    }
                </Grid>

             <AdvertisementDialog open={open}  onClose={()=>setOpen(false)} />
            </MainContentContainer>
            <Footer></Footer>
        </>
    )
}

export default AdvertisementContainer