import axios from "axios";
import getSessionData from "../../utils/getSession";

export const fetchMe = async () => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/users/me", { headers: sessionData })
};

export const getUsersNames = async (filter=undefined) => {
  const sessionData = getSessionData();
  if(filter)
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/users/list"+"?filter="+filter, {
      headers: sessionData,
    });
  }
  else
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/users/list", {
      headers: sessionData,
    });
  }
  
};

export const getUsersNamesWithFrequentPartners = async () => {
  const sessionData = getSessionData();
    return await axios.get(process.env.REACT_APP_API_URL + "/users/list?isFrequent=true", {
      headers: sessionData,
    });  
};

export const getUsers_api = async (filter=undefined) => {
  const sessionData = getSessionData();
  if(filter)
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/users?filter="+filter, {
      headers: sessionData,
    });
  }
  else
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/users", {
      headers: sessionData,
    });
  }
  
};

export const getRegistrations_api = async (filter) => {
  const sessionData = getSessionData();
  
  return await axios.get(process.env.REACT_APP_API_URL + "/users/signup?filter="+filter, {
    headers: sessionData,
  });
};

export const getUser = async (id) => {
  const sessionData = getSessionData();
  
  return await axios.get(
    process.env.REACT_APP_API_URL + "/users?id=" + id,
    { headers: sessionData }
  );
};

export const getCount_api = async () => {
  const sessionData = getSessionData();
  
  return await axios.get(
    process.env.REACT_APP_API_URL + "/users/count",
    { headers: sessionData }
  );
};

export const createRegistration_api = async (firstname,lastname,email,role) => {
  const sessionData = getSessionData();

  var data = {
    firstname: firstname,
    lastname: lastname,
    email: email,
    role:role
  }
  
  return await axios.post(process.env.REACT_APP_API_URL + "/users/",data, {
    headers: sessionData,
  });
};

export const deleteUser_api = async (id) => {
  const sessionData = getSessionData();

  //call enpoint to delete user
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/users/"+id,
    { headers: sessionData }
  );
};

export const updateUser = async (formData, id, notifications,sepa,comment) => {
  const sessionData = getSessionData();
  var role = null;

  //resolve role

  if (formData.get("role") == 1) {
    role = "user";
  } else {
    role = "admin";
  }

  const data = {
    firstName: formData.get("firstName"),
    lastName: formData.get("lastName"),
    email: formData.get("email"),
    nuligaLK: formData.get("nuliga_lk"),
    nuligaID: formData.get("nuliga_id"),
    nuligaName: formData.get("nuliga_name"),
    sepa: sepa,
    id: id,
    comment: comment,
    role: role,
    notifications: notifications
  };

  //fetch user data
  return await axios.patch(process.env.REACT_APP_API_URL + "/users", data, {
    headers: sessionData,
  });
};

export const updateMe = async (formData,notifications) => {
  const sessionData = getSessionData();
 
  const data = {
    firstName: formData.get("firstName"),
    lastName: formData.get("lastName"),
    email: formData.get("email"),
    nuligaLK: formData.get("nuliga_lk"),
    nuligaID: formData.get("nuliga_id"),
    nuligaName: formData.get("nuliga_name"),
    receiveMails: notifications
  };

  //post user data
  return await axios.post(process.env.REACT_APP_API_URL + "/users/me", data, {
    headers: sessionData,
  });
};

//Send mail to single user
export const sendUserMail_api = async(message,subject,id) => {
  const sessionData = getSessionData();
  
  var data= {
    message: message,
    subject: subject,
    userid: id
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/users/mail",data,
    { headers: sessionData }
  );
};

export const sendAllUsersMail_api = async (message,subject) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data = {
    message: message,
    subject: subject,
  };
  return await axios.post(
    process.env.REACT_APP_API_URL + "/users/mail/",data,
    { headers: sessionData }
  );
};

export const verifyUser_api = async (userid) => {
  const sessionData = getSessionData();
  
  var data ={
    userid: userid
  }
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/users/verify",data,
    { headers: sessionData }
  );
};

export const changePassword_api = async (password_old,password_new) => {
  const sessionData = getSessionData();
  
  var data ={
    passwordOld: password_old,
    passwordNew: password_new
  }
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/users/password",data,
    { headers: sessionData }
  );
};

export const resetPassword_api = async (mail) => {
  const sessionData = getSessionData();
  
  var data ={
    email: mail
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/users/password",data,
    { headers: sessionData }
  );
};

export const getActiveUsers_api = async () => {
  const sessionData = getSessionData();
 
  return await axios.get(
    process.env.REACT_APP_API_URL + "/users/online",
    { headers: sessionData }
  );
};

export const validateUsersBookingRequest_api = async (day,courtType,userid) => {
  const sessionData = getSessionData();

  var data = {
    day:day,
    courtType: courtType
  }
 
  return await axios.put(
    process.env.REACT_APP_API_URL + "/users/"+userid+"/validatebookingrequest",data,
    { headers: sessionData }
  );
};

export const getNuligaAccounts_api = async (query) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/users/nuligaaccounts"+query,
    { headers: sessionData }
  );
};

export const updateNuligaAccounts_api = async () => {
  const sessionData = getSessionData();
  var data = {}
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/users/nuligaaccounts",data,
    { headers: sessionData }
  );
};

export const disconnectNuligaAccount_api = async (userId) => {
  const sessionData = getSessionData();
  
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/users/"+userId+"/nuligaaccount",
    { headers: sessionData }
  );
};

export const connectNuligaAccount_api = async (userId,nuligaId,nuligaLk,nuligaName) => {
  const sessionData = getSessionData();
  var data ={
    nuligaId: nuligaId,
    nuligaName: nuligaName,
    nuligaLk: nuligaLk
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/users/"+userId+"/nuligaaccount",data,
    { headers: sessionData }
  );
};

export const updateComment_api = async (userId,comment) => {
  const sessionData = getSessionData();
  var data ={
    comment: comment
  }
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/users/"+userId+"/comment",data,
    { headers: sessionData }
  );
};


export const downloadUsersExport_api = async () => {
  const sessionData = getSessionData();
    return await axios.get(process.env.REACT_APP_API_URL + "/users/export", {
      responseType: 'text',headers: sessionData
    }); 
};

export const getUsersRemainingReservations_api = async (id,day,courtType) => {
  const sessionData = getSessionData();
  //fetch users data

  var data = {
    courtType: courtType,
    day: day
  }

  return await axios
   .post(process.env.REACT_APP_API_URL + "/users/"+id+"/remainingreservations",data, { headers: sessionData })
};


