import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, CircularProgress, Alert, Tooltip } from '@mui/material';
import { Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import BookingDialog from './BookingDialog';
import { addMinutes, addSeconds, differenceInMinutes, format, isValid, isWithinInterval } from 'date-fns';
import { myStyles } from '../../css/Sytles';
import BookingButtonContainer from './BookingButtonContainer';
import { ADM_COURTS } from '../../navigation/CONSTANTS';
import InternalLink from '../../components/InternalLink';
import { getUsersRemainingReservations_api, validateUsersBookingRequest_api } from '../../api/Users/users';
import { useSnackbar } from '../../components/SnackbarContext';
import BookingDateView from '../../components/BookingDateView';
import BookingTypeLegend from '../AdminBooking/BookingTypeLegend';
import CheckoutSlider from '../AdminBooking/CheckoutSlider';
import { useSelector } from 'react-redux';

function BookingTable(props) {
    //Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [newBookingData, setNewBookingData] = useState({ time: null, court: null, date: null, mate: null })
    const [shortText, setShortText] = useState();
    const { showSnackbar } = useSnackbar();
    const [bookingStart, setBookingStart] = useState();
    const [bookingEnd, setBookingEnd] = useState();
    const [bookingCourts, setBookingCourts] = useState([]);
    const [numBookingUnits, setNumBookingUnits] = useState(0)
    const [remainingBookings,setRemainingBookings] = useState();
    const [bookingLimitReached,setBookingLimitReached] = useState();
    const userId = useSelector((state) => state.id);

    const classes = myStyles();

    const getRemainingReservations = () => {
        var promise = getUsersRemainingReservations_api(userId,format(new Date(props.dayFilter), "yyyy-MM-dd"),props.courtType);
        promise.then((result)=>setRemainingBookings(result.data))
        .catch(()=>{});
    }

    const validateBookingRequest = (day, id) => {

        var promise = validateUsersBookingRequest_api(day, props.courtType, id);
        promise.then((result) => {
            if (result.data.validation_result == "true") {
                setModalOpen(true);
            }
            else if (props.settings['allow_freetext_bookings']) {
                showSnackbar("Du hast dein persönliches Limit für zukünftige Mitgliederbuchungen bereits erreicht. Freitextbuchungen sind allerdings noch möglich", "warning");
                setModalOpen(true);
            }
            else {
                showSnackbar("Du hast dein persönliches Limit für zukünftige Buchungen bereits erreicht", "warning");
            }
        });


    }

    const handleOpen = (event) => {
        validateBookingRequest(format(new Date(props.dayFilter), "yyyy-MM-dd"), props.userid);
    }

    const handleClose = () => setModalOpen(false);

    // Generate all days in the month
    // Map each date to the desired format
    const isHighlighted = (time) => {

        var reservationStart = new Date(props.dayFilter).setHours(time.split(":")[0], time.split(":")[1]);
        var reservationEnd = addMinutes(reservationStart, props.settings?.booking_duration)

        var isSelected = isWithinInterval(new Date(), { start: reservationStart, end: addMinutes(reservationEnd, -1) })
        if (isSelected) {
            return true
        }
        else {
            return false
        }
    }

    //calculate num bookings and cancel selection in case allowed amount is exceeded
    useEffect(() => {
        if (isValid(new Date(bookingStart)) && isValid(new Date(bookingEnd))) {
            var diffInMinutes = differenceInMinutes(bookingEnd, bookingStart);
            var numBookings = (diffInMinutes / props.settings?.booking_duration) * bookingCourts.length;
            setNumBookingUnits(numBookings);
            if ((numBookings > remainingBookings?.remainingPerDay || numBookings > remainingBookings?.remainingTotal) && props.settings?.allow_freetext_bookings) {
                showSnackbar("Du überschreitest dein Buchungskontigent - Mitglieder- & Gastbuchungen sind nicht möglich!", "warning");
                setBookingLimitReached(true);
            }
            else if((numBookings > remainingBookings?.remainingPerDay || numBookings > remainingBookings?.remainingTotal) && !props.settings?.allow_freetext_bookings)
            {
                showSnackbar("Du überschreitest dein Buchungskontigent!", "warning");
                setBookingEnd();
                setBookingStart();
                setBookingCourts([]);
                setBookingLimitReached(true);
            }
            else
            {
                setBookingLimitReached(false);
            }
        }
    }, [bookingEnd, bookingStart, bookingCourts]);

    useEffect(() => {
        try {
            if (props.reservations?.some(reservation => bookingCourts.some(courts => courts.id == reservation.platz) && isWithinInterval(addSeconds(new Date(reservation.timestamp), 1), { start: bookingStart, end: bookingEnd }))) {
                showSnackbar("Buchungszeitraum kollidiert mit einer bestehenden Buchung.", "warning");
                setBookingEnd();
                setBookingStart();
                setBookingCourts([]);
            }
        } catch (error) {
        }
    }, [bookingStart, bookingEnd, bookingCourts]);

    useEffect(() => {
        getRemainingReservations();
    }, [props.courtType,props.dayFilter]);

    return (
        <>
            <Grid container direction="row" alignItems="center" rowSpacing={2} columnSpacing={1} mb={1}>
                <Grid item xs={12}>
                    <BookingDateView shortText={shortText} setShortText={setShortText} date={props.dayFilter} setDate={props.setDayFilter} courts={props.courts} settings={props.settings}></BookingDateView>
                </Grid>
                <Grid item xs={12}>
                    <BookingTypeLegend />
                </Grid>
                {(!props.settings || props.settings['bookings_enabled'] != 1) && !props.dialogView ?
                    <Grid item xs={12}>
                        <Alert id="alt-booking-deactivated" severity='info'><Typography variant="body1">{"Buchungen für " + (props.courtType == "indoor" ? "Hallenplätze" : "Außenplätze") + " sind zurzeit deaktiviert"}</Typography></Alert>
                    </Grid>
                    : (!props.courts || props.courts.length < 1) ?
                        <Grid item xs={12}>
                            <Alert severity='info'><Typography variant="body1">Es sind keine Tennisplätze vorhanden</Typography><InternalLink linkname="Platzverwaltung" route={ADM_COURTS}></InternalLink></Alert>
                        </Grid>
                        :
                        <Grid item xs={12} align="center">
                            {!props.isLoading ?
                                <TableContainer sx={{ borderRadius: 5, left: 1, zIndex: 2, backgroundColor: "#eee" }}>
                                    <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '4px 4px' }}>
                                        <TableHead sx={{ borderRadius: 4 }}>
                                            <TableRow sx={{ borderRadius: 2, borderSpacing: '0px 0px' }}>
                                                <TableCell className={classes.tableHeader} sx={{ position: 'sticky', left: 0, zIndex: 1, border: 0, pl: 1, pr: 1, borderSpacing: 0, width: { xs: "45px", md: "70px" } }}>
                                                </TableCell>
                                                {props.courts.map((court, i) => (
                                                    <TableCell key={i} className={classes.tableHeader} align="center" sx={{ m: 1, border: 0, backgroundColor: "#fff" }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: "bold" }}>{court.name}</Typography></TableCell>
                                                ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.rows.map((row, i) => (
                                                <TableRow key={i} sx={{ borderRadius: 1 }}>
                                                    <TableCell key={i} sx={{ position: 'sticky', background: isHighlighted(row[0], props.settings) ? "#D9EFFF" : "#fff", left: 0, zIndex: 2, pl: 1, pr: 1, border: 0, textAlign: "center", width: { xs: "45px", md: "70px" } }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: 400 }}>{row[0]}</Typography></TableCell>
                                                    {props.courts.map((court, i) => (
                                                        <Tooltip title={court.name + " | " + row[0]} key={i} arrow placement='left' followCursor disableTouchListener>
                                                            <TableCell key={i} align="center" sx={{ border: 0, p: 0, backgroundColor: isHighlighted(row[0], props.settings) ? "#D9EFFF" : "#fff", borderRadius: "4px", maxWidth: { xs: "200px", md: "250px" } }}>
                                                                <BookingButtonContainer serieId={props.serieId} setTargetCourts={props.setTargetCourts} endtime={props.endtime} targetCourts={props.targetCourts} adminMode={props.adminMode} startdate={props.startdate} setEnddate={props.setEnddate} setStartdate={props.setStartdate} shortText={shortText} settings={props.settings} courts={props.courts} data={row[court.id]} zeit={row[0]} handleOpen={handleOpen} day={props.dayFilter} setNewBookingData={setNewBookingData} court={court} dialogView={props.dialogView} bookingStart={bookingStart} bookingEnd={bookingEnd} bookingCourts={bookingCourts} setBookingStart={setBookingStart} setBookingEnd={setBookingEnd} setBookingCourts={setBookingCourts} />
                                                            </TableCell>
                                                        </Tooltip>
                                                    ))
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <CircularProgress></CircularProgress>
                            }
                        </Grid>
                }

            </Grid >
            <BookingDialog open={modalOpen} bookingLimitReached = {bookingLimitReached} numBookings={numBookingUnits}bookingStart={bookingStart} bookingEnd={bookingEnd} bookingCourts={bookingCourts} setBookingCourts={setBookingCourts} setBookingStart={setBookingStart} setBookingEnd={setBookingEnd} dayFilter={props.dayFilter} onClose={handleClose} newBookingData={newBookingData} populateTable={props.populateTable} settings={props.settings} courtType={props.courtType} submitCallback={getRemainingReservations}></BookingDialog>
            <CheckoutSlider targetCourts={bookingCourts} startdate={bookingStart} enddate={bookingEnd} checkoutHandler={setModalOpen} />
        </>
    );
}



export default BookingTable
