import { ChevronLeft, ChevronRight, Group, Message, PersonAdd, } from '@mui/icons-material'
import { Grid, Typography, Alert, Skeleton, Container, Stack, Divider, Tabs, Box, Card, CardContent } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { deleteTeammate_api, getMyTeam, getTeamsSeries_api, sendTeamInvitation_api, sendTeammail_api } from '../../api/Teams/teams'
import { getUsersNames } from '../../api/Users/users'
import AddUserDialog from '../../components/AddUserDialog'
import Footer from '../../components/Footer'
import Header from '../../components/Header/Header'
import MailDialog from '../../components/MailDialog'
import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'
import TeamMemberCard from '../../components/TeamMemberCard'
import TeamStatsCard from '../../components/TeamStatsCard'
import TitleCard from '../../components/TitleCard'
import PrimaryIconButton from '../../components/PrimaryIconButton'
import MyTab from '../../components/MyTab'
import SeriesCard from '../../components/SeriesCard'
import { useSnackbar } from '../../components/SnackbarContext'
import MainContentContainer from '../../components/MainContentContainer'
import Masonry from '@mui/lab/Masonry';

function MyTeamContainer(props) {

    const [teams, setTeams] = useState();
    const [series, setSeries] = useState();
    const [index, setIndex] = useState(0);
    const [isLoading, setIsloading] = useState(true);
    const { showSnackbar } = useSnackbar();
    //add user Dialog vars
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [users, setUsers] = useState([{}]);

    const [openMailDialog, setOpenMailDialog] = useState(false);

    const getTeams = () => {
        var promise = getMyTeam();
        promise.then((result) => { setTeams(result.data); setIsloading(false) })
            .catch();
    }

    const sendTeamInvitaion = (teamid, userid) => {
        var promise = sendTeamInvitation_api(teamid, userid);
        promise.then((result) => { setOpenAddUserDialog(false); showSnackbar("User erfolgreich eingeladen!", "success"); })
            .catch((error) => { showSnackbar(`Fehler: ${error?.response?.data?.error}`, "error"); });
    }


    const deleteTeammate = (userid) => {
        var promise = deleteTeammate_api(teams[index].id, userid);
        promise.then((result) => { getTeams(); })
            .catch();
    }

    const handleSubmit = (message, subject) => {
        var promise = sendTeammail_api(message, subject, teams[index].id);
        promise.then((result) => { showSnackbar(result.data, "success"); setOpenMailDialog(false); })
            .catch((error) => { showSnackbar("Fehler beim Senden der Teammails", "error"); });
    }

    //add user dialog submit 
    const addUserDialogSubmit = (targetUserID) => {
        // var promise = addTeammate(team.id, targetUserID);
        // promise.then((result) => { getTeammatesFromTeam(); setOpenAddUserDialog(false); })
        //     .catch();
        sendTeamInvitaion(teams[index].id, targetUserID);
    }

    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
        })
            .catch(error => { });
    }

    const nextTeam = () => {
        if (index < teams.length - 1) {
            setIndex(index + 1);
        }
        else {
            setIndex(0);
        }
    }

    const previousTeam = () => {
        if (index >= 1) {
            setIndex(index - 1);
        }
        else {
            setIndex(teams.length - 1);
        }
    }

    const getTeamSeries = () => {
        var promise = getTeamsSeries_api(teams[index].id);
        promise.then((result) => { setSeries(result.data) })
            .catch();
    }

    useEffect(() => {
        getTeams();
    }, []);

    useEffect(() => {
        if (teams && teams.length > 0 && index >= 0) {
            getTeamSeries();
        }
    }, [index, teams]);
    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-myteam" title={"Deine Mannschaft!"} subheader={"Hier findest du deine Tabelle und Mannschaftskollegen"} icon={<Group sx={{ width: 40, height: 40 }}></Group>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer pb={4}>
                <Grid container item direction="row" justifyContent={"center"} rowSpacing={2} columnSpacing={2}>
                    {teams && teams.length > 0 ?
                        <>
                            {teams.length > 1 ?
                                <>
                                    <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                                            <PrimaryIconButton icon={<ChevronLeft sx={{ width: "40px", height: "40px" }} color="primary" />} onClickAction={() => previousTeam()}></PrimaryIconButton>
                                            <Typography variant="h4" color="primary">{teams[index].team}</Typography>
                                            <PrimaryIconButton icon={<ChevronRight sx={{ width: "40px", height: "40px" }} color="primary" />} onClickAction={() => nextTeam()}></PrimaryIconButton>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                                        <Divider></Divider>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
                                        <Tabs value={index} onChange={(event, newValue) => setIndex(newValue)} centered>
                                            {teams.map((team, i) => (
                                                <MyTab key={i} value={i} label={team.team}></MyTab>
                                            ))
                                            }
                                        </Tabs>
                                    </Grid>

                                </>
                                :
                                <></>
                            }

                            <Grid item xs={12} md={12} >
                                <Grid container direction="row" columnSpacing={2} justifyContent="flex-start">
                                    <Grid item>
                                        <PrimaryButton text="Spieler" startIcon={<PersonAdd />} onClick={() => { setOpenAddUserDialog(true); getUsersList(); }} />
                                    </Grid>
                                    <Grid item>
                                        <SecondaryButton text="Teamnachricht" startIcon={<Message />} onClick={() => { setOpenMailDialog(true); }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <></>
                    }
                </Grid>
                <Masonry columns={{ xs: 1, sm: 2, xl: 2 }} spacing={2} sx={{ width: "auto", m: 0 }}>
                    {teams && teams.length > 0 ?
                        <>
                            <TeamStatsCard team={teams[index]} raised={true} />
                            <TeamMemberCard team={teams && teams[index]} teammates={teams[index].mates} deleteHandler={deleteTeammate}></TeamMemberCard>
                        </>
                        :
                        <>
                            <Alert severity="info" variant="filled"><Typography>Du bist in keiner Mannschaft - werde Mitglied einer Mannschaft und nutze die Funtionen der Teamsseite!</Typography></Alert>
                        </>}

                    {series &&
                        <Card sx={{ borderRadius: 2 }} raised={true}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} align="left">
                                        <Typography variant="h6">Reservierungen Punktspiele</Typography>
                                    </Grid>
                                    <Grid item xs={12}><Divider></Divider></Grid>
                                    {series.length > 0 ? series.map((serie, i) => (
                                        <Grid item xs={12} key={i}>
                                            <SeriesCard serie={serie} getSeries={getTeamSeries} />
                                        </Grid>
                                    ))
                                        :
                                        <Grid item xs={12} >
                                            <Alert severity="info">
                                                <Typography>Keine Reservierungen vorhanden</Typography>
                                            </Alert>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>

                    }
                </Masonry>


            </MainContentContainer >
            <Footer />
            <AddUserDialog users={users} openAddUserDialog={openAddUserDialog} addUserDialogSubmit={addUserDialogSubmit} onClose={() => { setOpenAddUserDialog(false) }} title={"Spieler einladen"}></AddUserDialog>
            <MailDialog open={openMailDialog} onClose={() => { setOpenMailDialog(false) }} title="Teamnachricht" submitName="Abschicken" handleSubmit={handleSubmit} isHidden={true} />
        </>
    )
}


export default MyTeamContainer