import { Campaign, Clear, PersonAdd } from '@mui/icons-material';
import { Alert, Badge, Box, Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import AvatarInitials from './AvatarInitials';
import PrimaryButton from './PrimaryButton';


function TeamMemberCard(props) {
    const { team, teammates, deleteHandler, addMemberHandler } = props;
    const isAdmin = useSelector((state) => state.isAdmin);

    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>
                <CardContent>
                    <Grid container direction="row" alignItems="space-between" spacing={2}>
                        <Grid item xs align="left">
                            <Typography variant="h6">Mitglieder</Typography>
                        </Grid>
                        {addMemberHandler &&
                            <Grid item>
                                <PrimaryButton text={"Hinzufügen"} startIcon={<PersonAdd></PersonAdd>} size="small" onClick={addMemberHandler}></PrimaryButton>
                            </Grid>
                        }

                        <Grid item xs={12} >
                            <Divider></Divider>
                        </Grid>
                    </Grid>
                    <Stack direction="row" spacing={2}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Box sx={{ backgroundColor: "secondary.main", width: "6px", height: "12px" }}></Box>
                            <Typography variant="body2">Mannschaftsführer</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Box sx={{ backgroundColor: "primary.main", width: "6px", height: "12px" }}></Box>
                            <Typography variant="body2">Spieler</Typography>
                        </Stack>
                    </Stack>
                    <List dense sx={{maxHeight: "500px", overflowY: "auto"}}>
                        {teammates && teammates.length > 0 ? teammates.map((teammate, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar>
                                    <AvatarInitials firstname={teammate.firstname} lastname={teammate.surname} color={teammate.isCaptain == 1 ? "secondary.main" : "primary.main"}></AvatarInitials>
                                </ListItemAvatar>

                                {/* <ListItemAvatar><Person color="primary"/></ListItemAvatar> */}
                                <ListItemText primary={teammate.firstname + " " + teammate.surname} secondary={"LK: " + (teammate?.nuliga_lk ?? "")} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                </ListItemText>
                                {team?.isCaptain === 1 || isAdmin === true ? <IconButton onClick={(e) => { e.stopPropagation(); deleteHandler(teammate.user_id); }} sx={{ ml: 2 }}><Clear></Clear> </IconButton> : <></>}
                            </ListItem>
                        ))
                            :
                            <Alert severity="info"><Typography>Es gibt keine Teammitglieder</Typography></Alert>
                        }
                    </List>
                </CardContent>
            </Card>
        </>
    )
}




export default TeamMemberCard