import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getAds_api = async () => {
  const sessionData = getSessionData();
  
  return await axios.get(
    process.env.REACT_APP_API_URL + "/ads",
    { headers: sessionData }
  );
};

export const deleteAd_api = async (id) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/ads/" + id,
    { headers: sessionData }
  );
};

export const createAd_api = async (imageURL, link, name) => {
  const sessionData = getSessionData();

  var data = {
    imageUrl: imageURL,
    link: link,
    name: name
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/ads", data,
    { headers: sessionData }
  );
};

export const updateAd_api = async (imageURL, link,name, id) => {
  const sessionData = getSessionData();

  var data = {
    imageUrl: imageURL,
    link: link,
    name: name
  }
  return await axios.put(
    process.env.REACT_APP_API_URL + "/ads/" + id, data,
    { headers: sessionData }
  );
};

export const increaseHitAd_api = async (id) => {
  const sessionData = getSessionData();
  var data = {}

  return await axios.patch(
    process.env.REACT_APP_API_URL + "/ads/"+ id, data,
      { headers: sessionData }
  );
};



