import { Check, GridView, GroupAdd, Groups, Info, ViewListSharp, Warning } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Chip, CircularProgress, Collapse, Container, Divider, Grid, List, Stack, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getTeams_api } from '../../../api/Teams/teams'
import Header from '../../../components/Header/Header'
import TitleCard from '../../../components/TitleCard'
import AddEditTeamDialog from '../../../components/AddEditTeamDialog'
import Footer from '../../../components/Footer'
import TeamCardv2 from '../../../components/TeamCardv2'
import MyTab from '../../../components/MyTab'
import { useSearchParams } from 'react-router-dom'
import TertiaryButton from '../../../components/TertiaryButton'
import TeamsMenu from './TeamsMenu'
import { updateTeammatches_api } from '../../../api/Teammatches/Teammatches'
import MainContentContainer from '../../../components/MainContentContainer'




function AdmTeamsContainer() {

    const [state, setState] = useState(null);
    const [teams, setTeams] = useState(null);
    const [view, setView] = useState(false);
    const [tabValue, setTabValue] = useState("all");
    const [searchParams, setSearchParams] = useSearchParams();
    const [showLegend, setShowLegend] = useState(false);
    const [syncStatus, setSyncStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [teamNames, setTeamNames] = useState([]);
    const [menuMode, setMenuMode] = useState("menu");

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    }
    const handleView = (event, newView) => {
        setView(newView);
    }

    //dialog
    const [openAddTeamDialog, setOpenAddTeamDialog] = useState(false);

    const getTeams = () => {
        var promise = getTeams_api(searchParams.toString());
        promise.then((result) => { setTeams(result.data) })
            .catch();
    }

    const updateTeammatches = () => {
        setIsLoading(true);
        var promise = updateTeammatches_api();
        promise.then((result) => { setIsLoading(false); getTeams(); })
            .catch((error) => { setIsLoading(false); });
    }

    useEffect(() => {
        switch (tabValue) {
            case "all":
                searchParams.delete("type");
                break;
            case "women":
                searchParams.set("type", "women");
                break;
            case "men":
                searchParams.set("type", "men");
                break;
            default:
                break;
        }
        setSearchParams(searchParams);
        getTeams();
    }, [tabValue]);

    useEffect(() => {
        if (teams && teams.length > 0) {
            var tmpStatus = 1;
            //set sync status
            for (var i = 0; i < teams.length; i++) {
                if ((teams[i].sync_status === 0 || teams[i].sync_status === null) && teams[i].tnbid && teams[i].tnbchampionship) {
                    tmpStatus = 0;
                    break;
                }
            }
            setSyncStatus(tmpStatus);
            // prepare array with team names
            var teamNames = [];
            for (var i = 0; i < teams.length; i++) {
                teamNames.push(teams[i].team)

            }
            setTeamNames(teamNames);
        } else { setTeamNames([]); }
    }, [teams]);

    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-teams" title={"Mannschaften"} subheader={"Verwalte die Mannschaften vom HTC"} icon={<Groups sx={{ width: 40, height: 40 }}></Groups>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>

                <Grid container direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                            <Tabs onChange={handleChangeTab} value={tabValue}>
                                <MyTab label="Alle" value="all" />
                                <MyTab label="Damen" value="women" />
                                <MyTab label="Herren" value="men" />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" columnSpacing={2} justifyContent="space-between" rowSpacing={1}>
                            <Grid item xs={12}>
                                <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small" fullWidth>
                                    <ToggleButton value={false}> <GridView /> <Typography textTransform={"none"}>Kacheln</Typography></ToggleButton>
                                    <ToggleButton value={true}> <ViewListSharp /> <Typography textTransform={"none"}>Liste</Typography></ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                {!showLegend &&
                                    <TertiaryButton startIcon={<Info></Info>} text={"Status Legende"} onClick={() => { setShowLegend(true) }} />
                                }
                            </Grid>
                            <Grid item>
                                <TeamsMenu setOpenCreate={() => { setMenuMode("menu"); setOpenAddTeamDialog(true); }} updateTeammatches={updateTeammatches} setOpenChangeSeason={() => { setMenuMode("seasons"); setOpenAddTeamDialog(true) }}></TeamsMenu>
                            </Grid>
                        </Grid>
                    </Grid>
                        <Grid item xs={12}>
                            <Collapse in={showLegend}>
                                <Alert variant="outlined" severity="info" onClose={() => { setShowLegend(false) }}>
                                    <Box sx={{ width: "100%" }}>

                                        <Stack spacing={1} sx={{ width: "100%" }}>
                                            <AlertTitle>Legende</AlertTitle>
                                            <Stack direction="row" spacing={2} alignItems={"center"}>
                                                <Chip size="small" label={<Stack direction="row" spacing={1}><Check color="white" /><Typography color="#fff"> {"saison"}</Typography></Stack>} color="success" sx={{ backgroundColor: "success.light" }} />
                                                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>Mannschaft synchronisiert - Punktspiele gefunden</Typography>
                                            </Stack>
                                            <Divider></Divider>
                                            <Stack direction="row" spacing={2} alignItems={"center"}>
                                                <Chip size="small" label={<Stack direction="row" spacing={1}><Warning></Warning><Typography>{"saison"}</Typography></Stack>} color="warning" />
                                                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>Mannschaft synchronisiert - Punktspiele nicht vorhanden oder fehlerhaft</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Alert>
                            </Collapse>
                        </Grid>
                    {teams && teams.length > 0 ?
                        <Grid item xs={12}>
                            <Alert variant="standard" severity={isLoading ? "info" : syncStatus ? 'success' : "warning"}>
                                {isLoading ?
                                    <>
                                        <AlertTitle sx={{ width: "100%" }}>Nuliga Synchronisierung</AlertTitle>
                                        <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
                                            <CircularProgress></CircularProgress>
                                            <Typography>Synchronisiere..</Typography>
                                        </Stack>
                                    </>
                                    : syncStatus ?
                                        <>
                                            <AlertTitle>NuLiga Synchronisierung erfolgreich</AlertTitle>
                                            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>Alle Mannschaften wurden erfolgreich synchronisiert</Typography>
                                        </>
                                        :
                                        <>
                                            <AlertTitle>NuLiga Synchronisierung fehlerhaft</AlertTitle>
                                            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>Mindestens eine Mannschaft oder ein Punktspiel wurde nicht erfolgreich synchronisiert (evtl. hilft eine neue Synchronisierung)</Typography>
                                        </>

                                }
                            </Alert>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Alert severity='info'>
                                <AlertTitle>Keine Mannschaften vorhanden</AlertTitle>
                                <Typography>Es sind bisher keine Mannschaften angelegt.<span> <TertiaryButton startIcon={<GroupAdd />} text="Mannschaft erstellen" onClick={() => { setMenuMode("menu"); setOpenAddTeamDialog(true) }} sx={{ width: "auto", textTransform: "none" }} /></span></Typography>
                            </Alert>
                        </Grid>
                    }

                    <Grid container item xs={12} spacing={4}>
                        {teams && !isLoading ?
                            <>
                                {/* {teams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((team, i) => ( */}
                                {view ?
                                    <Grid item xs={12} justifyContent={"center"}>
                                        <List>
                                            {teams.map((team, i) => (
                                                <TeamCardv2 key={i} team={team} getTeams={getTeams} listview />
                                            ))}
                                        </List>
                                    </Grid>
                                    :
                                    teams.map((team, i) => (
                                        <Grid item key={i} xs={12} md={6} xl={4}>
                                            <TeamCardv2 team={team} getTeams={getTeams}></TeamCardv2>
                                        </Grid>
                                    ))}
                            </>
                            :

                            <></>
                        }
                    </Grid>
                </Grid>
            </MainContentContainer >
            <Footer />
            <AddEditTeamDialog open={openAddTeamDialog} onClose={() => { setOpenAddTeamDialog(false); }} getAllTeams={getTeams} isEdit={false} teamNames={teamNames} menuMode={menuMode} teams={teams}></AddEditTeamDialog>
        </>
    )
}

export default AdmTeamsContainer