import { Info, ShoppingCart } from '@mui/icons-material'
import { Box, Container, Paper, Slide, Stack, Typography } from '@mui/material'
import { format, isValid } from 'date-fns'
import React from 'react'
import PrimaryButton from '../../components/PrimaryButton'

function CheckoutSlider({targetCourts,startdate,enddate,checkoutHandler}) {
  return (
    <Box sx={{ position: "fixed", bottom: "0px", left: "50%", transform: "translate(-50%)", width: "100%", zIndex: 1000, backgroundColor: "#eee", px: 0 }}>
    <Slide in={Boolean(targetCourts && targetCourts.length > 0 & isValid(new Date(startdate)) && isValid(new Date(enddate)))} timeout={{ enter: 500, exit: 500 }} direction="up" mountOnEnter unmountOnExit>
        <Paper elevation={10} >

            <Container maxWidth="md" sx={{ px: 0, pt: 1 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
                    <Info color="action" />
                    <Typography>Wähle die gewünschten Buchungseinheiten aus!</Typography>
                </Stack>
                <Stack spacing={0} alignItems={"center"}>
                    <Stack direction={"row"} spacing={1}>
                        <Typography fontWeight={500} variant="h6">{isValid(new Date(startdate)) && format(startdate, "dd.MM.yyyy")}</Typography>
                        <Typography fontWeight={500} variant="h6">{isValid(new Date(startdate)) && format(startdate, "H:mm")}</Typography>
                        <Typography fontWeight={500} variant="h6"> - </Typography>
                        <Typography fontWeight={500} variant="h6">{isValid(new Date(enddate)) && format(enddate, "H:mm")}</Typography>
                    </Stack>
                    <Typography fontWeight={500}>{targetCourts?.map((court, i) => (i>=targetCourts.length-1 ? court.name :court.name + ", "))}</Typography>
                </Stack>
            </Container>
            <PrimaryButton id="btn-checkoutslider-submit" text="Weiter zum Buchungsmenü" fullWidth startIcon={<ShoppingCart></ShoppingCart>} size="medium" onClick={() => checkoutHandler(true)} sx={{ textTransform: "none", pb: 3,fontSize: (theme) => theme.typography.h6.fontSize,  }} />
        </Paper>
    </Slide>
</Box>
  )
}

export default CheckoutSlider