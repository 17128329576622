import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getTeams_api = async (query) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(process.env.REACT_APP_API_URL + "/teams?"+query, {
    headers: sessionData,
  });
};

export const getTeammates_api = async (teamid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(process.env.REACT_APP_API_URL + "/teams/"+teamid + "/teammates", {
    headers: sessionData,
  });
};

export const createTeam = async (teamname,teamcaptain,tnbid,season,reqTeamSize,type) => {
  const sessionData = getSessionData();
  
  var data = {
    teamname: teamname,
    captain: teamcaptain,
    tnbid: tnbid,
    season: season,
    reqTeammatchSize: reqTeamSize,
    type: type
  };
  return await axios.post(process.env.REACT_APP_API_URL + "/teams",data,{
    headers: sessionData,
  });
};

export const updateTeam = async (teamname,teamcaptain,tnbid,season,teamid,reqTeamSize,type) => {
  const sessionData = getSessionData();
  
  var data = {
    teamname: teamname,
    captain: teamcaptain,
    tnbid: tnbid,
    season: season,
    teamid: teamid,
    reqTeammatchSize: reqTeamSize,
    type: type
  };
  return await axios.patch(process.env.REACT_APP_API_URL + "/teams",data,{
    headers: sessionData,
  });
};

export const deleteTeams = async (teamid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(process.env.REACT_APP_API_URL + "/teams/"+teamid, {
    headers: sessionData,
  });
};


export const getMyTeam = async (teamid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  if(teamid)
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/myteam?id="+teamid, {
      headers: sessionData,
    });
  }
  else
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/myteam", {
      headers: sessionData,
    });
  }
 
};

export const addTeammate = async (teamid, userid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data = {
    userid: userid,
    teamid: teamid,
  };
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/teams/"+ teamid + "/teammates/" +userid ,data,
    { headers: sessionData }
  );
};

export const deleteTeammate_api = async (teamid,userid) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/teams/"+teamid +"/teammates/"+ userid,
    { headers: sessionData }
  );
};

export const deleteMyTeammate = async (userid) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/myteam/teammates/" + userid,
    { headers: sessionData }
  );
};

export const sendTeammail_api = async (message,subject,teamid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data = {
    message: message,
    subject: subject,
  };
  return await axios.post(
    process.env.REACT_APP_API_URL + "/teams/"+ teamid + "/mail/" ,data,
    { headers: sessionData }
  );
};

export const sendTeamInvitation_api = async (teamid,userid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data = {
    userid: userid
  };
  return await axios.post(
    process.env.REACT_APP_API_URL + "/teams/"+ teamid + "/invitation/" ,data,
    { headers: sessionData }
  );
};

export const getTeamByCode_api = async (teamId,code) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  return await axios.get(
    process.env.REACT_APP_API_URL + "/teams/"+ teamId + "/invitation?code="+code ,{ headers: sessionData }
  );
};

export const getNuligaTeams_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  return await axios.get(
    process.env.REACT_APP_API_URL + "/teams/nuliga",{ headers: sessionData }
  );
};

export const getTeamsSeries_api = async (teamid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  return await axios.get(
    process.env.REACT_APP_API_URL + "/teams/"+teamid+"/reservations",{ headers: sessionData }
  );
};

