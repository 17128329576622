import { Container, Grid, Stack} from '@mui/material'
import React from 'react'
import { increaseHitAd_api } from '../api/Advertisements/advertisements';
import { useAdvertisements } from '../hooks/useAdvertisement';

function Advertisement() {

    const { data: advertisements, isLoading, error } = useAdvertisements();
    
    const increaseHit = (id) => {
        var promise = increaseHitAd_api(id)
        promise.then()
        .catch();
    }

    const clickHandler = (banner) => {
        increaseHit(banner.id)
        window.open(banner.link, '_blank').focus()
    }

    return (
        <>
            {advertisements &&
                <Container maxWidth="xl" sx={{ px: 1 }}>
                    {/* <Typography variant='h6' color="text.secondary">Sponsoren</Typography> */}
                    <Grid container justifyContent={"space-around"} rowSpacing={1} columnSpacing={4} alignItems={"center"}>
                        {advertisements?.map((banner, i) => (
                            <Grid item xs={6} sm={4} key={i}>
                                <Stack direction="row" justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100px" }} onClick={()=>clickHandler(banner)}>
                                    <img
                                        src={banner.image_url}
                                        alt={"Werbebanner " + i}
                                        style={{
                                            maxHeight: "100px", // Ensures the image fits inside the box
                                            maxWidth: "100%", // Prevents stretching
                                            objectFit: "contain",
                                            cursor: "pointer"
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            }
        </>

    )
}

export default Advertisement