import getSessionData from "../utils/getSession";
import api from "./api";

const adPath = "/ads"



export const fetchAdvertisements = async () => {
    const sessionData = getSessionData();
    const response = await api.get(adPath,{ headers: sessionData });
    return response.data;
};

export const createAdvertisement = async (data) => {
    const sessionData = getSessionData();
    const response = await api.post(adPath,data,{ headers: sessionData });
    return response.data;
};

export const updateAdvertisement = async (data,id) => {
    const sessionData = getSessionData();
    const response = await api.put(adPath+`/${id}`,data,{ headers: sessionData });
    return response.data;
};

export const deleteAdvertisement = async (id) => {
    const sessionData = getSessionData();
    await api.delete(adPath+`/${id}`,{ headers: sessionData });
};