import React, { useEffect, useState } from 'react'
import MainContentContainer from '../../components/MainContentContainer'
import Footer from '../../components/Footer'
import Header from '../../components/Header/Header'
import TitleCard from '../../components/TitleCard'
import { EmojiEvents } from '@mui/icons-material'
import { useParams, useSearchParams } from 'react-router-dom'
import { deleteGuest_api, deleteTeammachtStatus_api, getMyGuestMatches, getMyTeammatches, getTeammatches_api, setInitialParticipantStatus, updateParticipantStatus } from '../../api/Teammatches/Teammatches'
import { Alert, Breadcrumbs, Card, CardContent, Link, Stack,  Typography } from '@mui/material'
import Survey from '../../components/Survey'

import { ADM_TEAMMATCHES, MYTEAMMATCHES } from '../../navigation/CONSTANTS'
import Masonry from "@mui/lab/Masonry/Masonry";
import TeammatchInfoCard from '../../components/TeammatchInfoCard'
import TeammatchDirectionsCard from '../../components/TeammatchDirectionsCard'

function TeammatchContainer({ adminMode,isGuest }) {

    const { id } = useParams();
    const [teammatch, setTeammatch] = useState();
    const [disabled,setDisabled] = useState();
    const [searchParams, setSearchParams] = useSearchParams();

    const getTeammatch = () => {
        var promise = null;
        if (adminMode) {
            promise = getTeammatches_api(searchParams.toString());
        }
        else if(searchParams.get('isGuest')=="true")
        {
            promise = getMyGuestMatches();
        }
        else {
            promise = getMyTeammatches(searchParams.toString());
        }

        promise.then((result) => {
            //FE filtering - api isn capable fpor guestmatches
            if(searchParams.get('isGuest')=="true")
            {
                setTeammatch(result?.data?.filter((teammatch)=>teammatch.id==id)[0])
            }
            else
            {
                setTeammatch(result.data[0])
            }

        })
            .catch((error) => { });
    }

     const setStatusTeammatch = (id, target_status, userid, current_status) => {
            setDisabled(true);
            var promise = null;
            if (current_status != null) {
                promise = updateParticipantStatus(id, target_status, userid);
            }
            else {
                promise = setInitialParticipantStatus(id, target_status, userid);
            }
            promise.then(result => {
                    getTeammatch();
                setDisabled(false);
            })
                .catch((error) => { setDisabled(false); });
        }

        const deleteStatusTeammatch = (teammatchid, userid) => {
            var promise = deleteTeammachtStatus_api(teammatchid, userid);
            promise.then((result) => { getTeammatch(); })
                .catch(error => { });
        }
    
        const deleteGuest = (temamatchid, userid) => {
            var promise = deleteGuest_api(temamatchid, userid);
            promise.then((result) => { getTeammatch(); })
                .catch((error) => { })
        }

    useEffect(() => {
        searchParams.set("id", id);
        getTeammatch();
    }, []);


    return (
        <>
            <Header />
            <TitleCard id="crd-title-teammatchpage" title={"Punktspieldetails"} subheader={"Eine Detailübersicht für Punkstpiele"} icon={<EmojiEvents sx={{ width: 40, height: 40 }} />}></TitleCard>

            <MainContentContainer pb={4}>
                <Breadcrumbs sx={{ pl: 1 }}>
                    <Link underline="hover" color='inherit' href={adminMode ? ADM_TEAMMATCHES : MYTEAMMATCHES}>
                        {adminMode ? "Punkspiele" : "Meine Punktspiele"}
                    </Link>
                    <Typography>{teammatch?.opponent}</Typography>
                </Breadcrumbs>
                <Masonry columns={{ xs: 1, md: 2, xl: 2 }} spacing={2} sx={{ m: 0 }}>
                    <TeammatchInfoCard teammatch={teammatch} setStatusTeammatch={setStatusTeammatch} deleteStatusTeammatch={deleteStatusTeammatch} disableParticipantsButton={disabled} deleteGuest={deleteGuest}/>
                    <TeammatchDirectionsCard teammatch={teammatch} />
                    {teammatch?.assignmentsData &&
                        <Card>
                            <CardContent>
                                <Stack spacing={2}>
                                    <Typography color="secondary" fontWeight={'bold'} textTransform={"uppercase"}>Umfragen</Typography>
                                    {teammatch?.assignmentsData?.length > 0 ?
                                        teammatch?.assignmentsData.map((survey, i) => (
                                            <Survey key={i} variant="raised" surveyData={survey} />
                                        ))
                                        :
                                        <Alert severity='info'>Keine verknüpften Umfragen vorhanden</Alert>
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                    }
                </Masonry>
            </MainContentContainer>
            <Footer />
        </>
    )
}

export default TeammatchContainer