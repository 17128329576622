
import { useSnackbar } from "../components/SnackbarContext";
import { createAdvertisement, fetchAdvertisements,deleteAdvertisement,updateAdvertisement} from "../services/advertisementService";
import { useMutation, useQuery, useQueryClient } from 'react-query'



export const useAdvertisements = () => {
  return useQuery({
    queryKey: ["advertisement"],
    queryFn: fetchAdvertisements,
    staleTime: 1000 * 60 * 30
  });
};

export const useCreateAdvertisement = () => {
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: createAdvertisement,
      onSuccess: (data, variables, context) =>  {
        queryClient.invalidateQueries(["advertisement"]);
        showSnackbar("Werbebanner erfolgreich erstellt", "success"); 

      },
      onError: (error) => {
        showSnackbar(`Fehler beim Erstellen: ${error.response?.data?.error || 'Unbekannter Fehler'}`, "error"); 
      },
    });
  };

  export const useUpdateAdvertisement = () => {
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: ({id,...data}) => updateAdvertisement(data,id),
      onSuccess: () => {
        queryClient.invalidateQueries(["advertisement"]);
        showSnackbar("Werbebanner erfolgreich bearbeitet", "success");
        
      },
      onError: (error) => {
        showSnackbar(`Fehler beim Erstellen: ${error.response?.data?.error || 'Unbekannter Fehler'}`, "error"); 
      },
    });
  };

  export const useDeleteAdvertisement = () => {
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: deleteAdvertisement,
      onSuccess: () => {
        queryClient.invalidateQueries(["advertisement"]);
        showSnackbar("Werbebanner erfolgreich gelöscht", "success"); 
      },
      onError: (error) => {
        showSnackbar(`Fehler beim Löschen: ${error.response?.data?.error || 'Unbekannter Fehler'}`, "error"); 
      },
    });
  };