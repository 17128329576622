import { Card, CardActions, CardContent, CardMedia, Divider, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import TertiaryButton from '../../../components/TertiaryButton'
import SecondaryButton from '../../../components/SecondaryButton'
import AdvertisementDialog from './AdvertisementDialog'
import { useDeleteAdvertisement } from '../../../hooks/useAdvertisement'
import { Info, InfoOutlined } from '@mui/icons-material'

function AdvertisementCard({ banner, getAds, ...props }) {
    const [open, setOpen] = useState(false);
    const { mutate, isLoading, error } = useDeleteAdvertisement();

    const deleteAd = () => {
        mutate(banner.id);
    }

    return (
        <>
            <Card>
                <CardMedia component={"img"} image={banner.image_url} alt="werbebanner" sx={{ height: "150px", objectFit: "contain", backgroundColor: "#eee" }}></CardMedia>
                <Divider></Divider>
                <CardContent>
                    <Stack >
                        <Typography variant="h6" color="text.secondary">{banner.name}</Typography>
                        <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant="h2" color="text.secondary">{banner.hits}</Typography>
                            <Typography color="text.secondary">Klicks</Typography>
                            <Tooltip title="Die Klickzahl wird alle 30 Minuten aktualisiert">
                                <InfoOutlined color={"grey"}/>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </CardContent>
                <Divider></Divider>
                <CardActions sx={{ justifyContent: "space-between" }}>
                    <TertiaryButton text="Löschen" onClick={() => { deleteAd() }} />
                    <SecondaryButton text="Bearbeiten" onClick={() => { setOpen(true); }} />
                </CardActions>
            </Card>
            <AdvertisementDialog open={open} onClose={() => { setOpen(false) }} banner={banner} isEdit={true} getAds={getAds}></AdvertisementDialog>
        </>
    )
}

export default AdvertisementCard