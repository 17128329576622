import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux"; // Import useSelector hook
import { ROOT, LOGIN, DASHBOARD, USERS, SIGNUP, USERSDETAILS, USERSME, LOG, BOOKING, MYBOOKINGS, ADM_BOOKING, MYTEAMMATCHES, MYTRAININGGROUPS, MYTEAM, ADM_TEAMMATCHES, ADM_TEAMS, ADM_TRAININGGROUPS, ADM_SERIES, ADM_EVENTS, MYEVENTS, TERMS, ADM_DASHBOARD, BOOKING_Date, ADM_STATISTICS, FEEDBACK, IMPRESSUM, ADM_RESERVATIONS, ADM_COURTS, BLOG, ADM_BLOG, ADM_SURVEYS, MYSURVEYS, BRACKET, MYCONFIRMATION, MYEVENT, SETTINGS, SUPPORT, BLOGDETAILED, POST, MYTEAMCONFIRMATION, RELEASENOTES, LICENSEAGREEMENT, ADM_EVENT, ADM_TEMPLATES, TOURNAMENT, ADM_COURTLOCKS, ADM_COURTDETAILS, ADM_ADVERTISEMENTS, ADM_TEAMSPAGE, ADM_TEAMMATCHPAGE, MYTEAMMATCH } from "./CONSTANTS.js";
import LoginContainer from "../pages/Login/LoginContainer.jsx";
import DashboardContainer from "../pages/Dashboard/DashboardContainer.jsx";
import NotFound from "./NotFound.jsx";
import UsersContainer from "../pages/Users/UsersContainer.jsx";
import SignUp from "../pages/SignUp/SignupView.jsx";
import UsersDetails from "../pages/Users/details/UsersDetail.jsx";
import LogContainer from "../pages/Log/LogContainer.jsx";
import BookingContainer from "../pages/booking/BookingContainer.jsx";
import MyBoookingsContainer from "../pages/MyBookings/MyBoookingsContainer.jsx";
import AdminBookingContainer from "../pages/AdminBooking/AdmBookingContainer.jsx";
import MyTeammatchesContainer from "../pages/MyTeammatches/MyTeammatchesContainer.jsx";
import MyTraininggroupsContainer from "../pages/MyTraininggroups/MyTraininggroupsContainer.jsx";
import MyTeamContainer from "../pages/MyTeam/MyTeamContainer.jsx";
import AdmTeammatchesContainer from "../pages/Admin/Teammatches/AdmTeammatchesContainer.jsx";
import AdmTeamsContainer from "../pages/Admin/Teams/AdmTeamsContainer.jsx";
import AdmTraininggroupsContainer from "../pages/Admin/Traininggroups/AdmTraininggroupsContainer.jsx";
import AdmSeriesContainer from "../pages/Admin/Series/AdmSeriesContainer.jsx";
import AdmEventsContainer from "../pages/Admin/Events/AdmEventsContainer.jsx";
import EventsContainer from "../pages/MyEvents/EventsContainer.jsx";
import TermsContainer from "../pages/Terms/TermsContainer.jsx";
import { updateAnalytics_api } from "../api/Analytics/analytics.js";
import AdmDashboardContainer from "../pages/Admin/Dashboard/AdmDashboardContainer.jsx";
import AdmStatisticsContainer from "../pages/Admin/Statistics/AdmStatisticsContainer.jsx";
import FeedbackContainer from "../pages/Feedback/FeedbackContainer.jsx";
import ImpressumContainer from "../pages/Impressum/ImpressumContainer.jsx";
import AdmReservationsContainer from "../pages/Admin/Reservations/AdmReservationsContainer.jsx";
import AdmCourtsContainer from "../pages/Admin/Courts/AdmCourtsContainer.jsx";
import AdmPostContainer from "../pages/Admin/Posts/AdmPostContainer.jsx";
import HomeContainer from "../pages/Home/HomeContainer.jsx";
import BlogContainer from "../pages/Blog/BlogContainer.jsx";
import AdmSurveyContainer from "../pages/Admin/Surveys/AdmSurveyContainer.jsx";
import MySurveyContainer from "../pages/MySurveys/MySurveyContainer.jsx";
import MyBookingConfimationContainer from "../pages/MyBookings/MyBookingConfimationContainer.jsx";
import ProfileContainer from "../pages/Profile/ProfileContainer.jsx";
import SettingsContainer from "../pages/Admin/Settings/SettingsContainer.jsx";
import SupportContainer from "../pages/Support/SupportContainer.jsx";
import PostContainer from "../pages/Blog/PostContainer.jsx";
import MyTeamConfirmationContainer from "../pages/MyTeam/MyTeamConfirmationContainer.jsx";
import ReleaseNotesContainer from "../pages/ReleaseNotes/ReleaseNotesContainer.jsx";
import LicenseAgreementContainer from "../pages/LicenseAgreement/LicenseAgreementContainer.jsx";
import { HelpCenterProvider } from "../components/HelpCenter/HelpMenu";
import TemplatesContainer from "../pages/Admin/Templates/TemplatesContainer.jsx";
import TournamentContainer from "../pages/Tournament/TournamentContainer.jsx";
import AdmCourtLocksContainer from "../pages/Admin/CourtLocks/AdmCourtLocksContainer.jsx";
import CourtDetailsContainer from "../pages/Admin/Courts/CourtDetailsContainer.jsx";
import ErrorBoundary from "../pages/ErrorBoundary/ErrorBoundary.jsx";
import AdvertisementContainer from "../pages/Admin/Advertisement/AdvertisementContainer.jsx";
import TeamContainer from "../pages/Admin/Teams/TeamContainer.jsx";
import TeammatchContainer from "../pages/MyTeammatches/TeammatchContainer.jsx";

const RouterConfig = () => {
  // Access Redux state using useSelector
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const isAdmin = useSelector((state) => state.isAdmin);
  const location = useLocation();

  // Update analytics on location change
  const updateAnalytics = () => {
    var promise = updateAnalytics_api(location.pathname);
    promise.then().catch((error) => {});
  };

  useEffect(() => {
    updateAnalytics(location.pathname);
    return () => {};
  }, [location.pathname]);

  return (
    <div>
      <HelpCenterProvider>
        <ErrorBoundary path={location.pathname}>
        <Routes>
          {/* Public Routes */}
          <Route exact path={LOGIN} element={<LoginContainer />} />
          <Route exact path={ROOT} element={<HomeContainer />} />
          <Route exact path={SIGNUP} element={<SignUp />} />
          <Route exact path={TERMS} element={<TermsContainer />} />
          <Route exact path={IMPRESSUM} element={<ImpressumContainer />} />
          <Route exact path={BLOG} element={<BlogContainer />} />
          <Route exact path={POST} element={<PostContainer />} />
          <Route path={MYCONFIRMATION} element={<MyBookingConfimationContainer />} />
          <Route path={RELEASENOTES} element={<ReleaseNotesContainer />} />

          {/* Private Routes */}
          <Route exact path={DASHBOARD} element={isAuthenticated ? <DashboardContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={USERSME} element={isAuthenticated ? <ProfileContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={BOOKING} element={isAuthenticated ? <BookingContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={BOOKING_Date} element={isAuthenticated ? <BookingContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYBOOKINGS} element={isAuthenticated ? <MyBoookingsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYTEAMMATCHES} element={isAuthenticated ? <MyTeammatchesContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYTRAININGGROUPS} element={isAuthenticated ? <MyTraininggroupsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYTEAM} element={isAuthenticated ? <MyTeamContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYEVENTS} element={isAuthenticated ? <EventsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYEVENT} element={isAuthenticated ? <EventsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={FEEDBACK} element={isAuthenticated ? <FeedbackContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYSURVEYS} element={isAuthenticated ? <MySurveyContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={TOURNAMENT} element={isAuthenticated ? <TournamentContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={SUPPORT} element={isAuthenticated ? <SupportContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYTEAMCONFIRMATION} element={isAuthenticated ? <MyTeamConfirmationContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={LICENSEAGREEMENT} element={isAuthenticated ? <LicenseAgreementContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={MYTEAMMATCH} element={isAuthenticated  ? <TeammatchContainer/> : <LoginContainer currentPath={location} />} />

          {/* Admin Routes (Private + Admin Check) */}
          <Route path={USERS} element={isAuthenticated && isAdmin ? <UsersContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={USERSDETAILS} element={isAuthenticated && isAdmin ? <UsersDetails /> : <LoginContainer currentPath={location} />} />
          <Route path={LOG} element={isAuthenticated && isAdmin ? <LogContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_BOOKING} element={isAuthenticated && isAdmin ? <AdminBookingContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_TEAMMATCHES} element={isAuthenticated && isAdmin ? <AdmTeammatchesContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_TEAMS} element={isAuthenticated && isAdmin ? <AdmTeamsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_TRAININGGROUPS} element={isAuthenticated && isAdmin ? <AdmTraininggroupsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_SERIES} element={isAuthenticated && isAdmin ? <AdmSeriesContainer />: <LoginContainer currentPath={location} />} />
          <Route path={ADM_EVENTS} element={isAuthenticated && isAdmin ? <AdmEventsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_EVENT} element={isAuthenticated && isAdmin ? <AdmEventsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_DASHBOARD} element={isAuthenticated && isAdmin ? <AdmDashboardContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_STATISTICS} element={isAuthenticated && isAdmin ? <AdmStatisticsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_RESERVATIONS} element={isAuthenticated && isAdmin ? <AdmReservationsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_COURTS} element={isAuthenticated && isAdmin ? <AdmCourtsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_BLOG} element={isAuthenticated && isAdmin ? <AdmPostContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_SURVEYS} element={isAuthenticated && isAdmin ? <AdmSurveyContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_TEMPLATES} element={isAuthenticated && isAdmin ? <TemplatesContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={SETTINGS} element={isAuthenticated && isAdmin ? <SettingsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_COURTLOCKS} element={isAuthenticated && isAdmin ? <AdmCourtLocksContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_COURTDETAILS} element={isAuthenticated && isAdmin ? <CourtDetailsContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_ADVERTISEMENTS} element={isAuthenticated && isAdmin ? <AdvertisementContainer /> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_TEAMSPAGE} element={isAuthenticated && isAdmin ? <TeamContainer/> : <LoginContainer currentPath={location} />} />
          <Route path={ADM_TEAMMATCHPAGE} element={isAuthenticated  ? <TeammatchContainer adminMode/> : <LoginContainer currentPath={location} />} />



          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        </ErrorBoundary>
      </HelpCenterProvider>
    </div>
  );
};

export default RouterConfig;
