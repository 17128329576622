import { Alert, Card, CardContent, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react'
import TeammatchParticipantsList from './TeammatchParticipantsList';

function TeammatchInfoCard(props) {
    const { teammatch, raised } = props;

    return (
        <Card raised={raised} sx={{ borderRadius: 2, border: 0, width: 'inherit' }}>
            <CardContent>
                <Grid container item direction="row" alignItems="center" spacing={2}>
                    {teammatch ?
                        <>
                            {/* <CardHeader title={teammatch.teammatch || teammatch.opponent} titleTypographyProps={{ variant: "h6" }} subheader={teammatch.league || teammatch.location} /> */}

                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <Stack>
                                        {/* <Typography color="secondary">Gegner</Typography> */}
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant="body1" color="secondary" fontWeight={500} textTransform={"uppercase"}>{teammatch?.location == "(H)" ? "Heim" : "Auswärts"}</Typography>
                                            <Link color="primary" href={teammatch.link} variant="body1" target="_blank" rel="noreferrer">NuLiga</Link>
                                        </Stack>
                                        <Typography variant="h5">{teammatch?.opponent}</Typography>
                                        <Typography variant="body1" color="text.secondary">{format(new Date(teammatch?.date), "EEEE d. MMMM HH:mm ", { locale: de })}</Typography>

                                    </Stack>
                                    <Stack>
                                        <Typography variant="h6" color="text.secondary">{teammatch && teammatch.tnbchampionship && decodeURIComponent(teammatch.tnbchampionship)}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider></Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    {teammatch?.matchpoints && teammatch?.points && teammatch?.ranking ?
                                        <Stack direction="row" justifyContent={"space-around"}>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <Typography align="right" variant={"h4"} color="secondary">{teammatch.ranking}</Typography>
                                                <Typography align="left" variant={"body1"} fontWeight={'bold'}>Ranking</Typography>
                                            </Stack>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <Typography align="right" variant={"h4"} color="secondary">{teammatch.points}</Typography>
                                                <Typography align="left" variant={"body1"} fontWeight={'bold'}>Punkte</Typography>
                                            </Stack>
                                            <Stack justifyContent={"center"} alignItems={"center"}>
                                                <Typography align="right" variant={"h4"} color="secondary">{teammatch.matchpoints}</Typography>
                                                <Typography align="left" variant={"body1"} fontWeight={'bold'}>Matchpunkte</Typography>
                                            </Stack>
                                        </Stack>
                                        :
                                        <Alert severity='info'>Keine Nuliga Informationen vorhanden!</Alert>
                                    }

                                </Grid>
                                <Grid item xs={12}>
                                    <Divider></Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography color="secondary" fontWeight={"bold"} textTransform={"uppercase"}>Teilnehmer</Typography>
                                        <Typography color="primary" fontWeight={'bold'}>{`${teammatch?.totalParticipants} Zusagen`}</Typography>
                                    </Stack>

                                    <TeammatchParticipantsList teammatch={teammatch} maxHeight={"400px"} setStatusTeammatch={props.setStatusTeammatch} deleteStatusTeammatch={props.deleteStatusTeammatch} disableParticipantsButton={props.disableParticipantsButton} deleteGuest={props.deleteGuest}></TeammatchParticipantsList>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Alert severity="info"><Typography>Keine Nuliga Informationen vorhanden</Typography></Alert>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TeammatchInfoCard